export const Types = {
  RESET_DATA: "farmSell/RESET_DATA",
  SET_CLOSED_REPORT_REQUEST: "farmSell/SET_CLOSED_REPORT_REQUEST",
  SET_CLOSED_REPORT_RESPONSE_DATA: "farmSell/SET_CLOSED_REPORT_RESPONSE_DATA",
  CLEAN_CLOSED_REPORT_REQUEST: "farmSell/CLEAN_CLOSED_REPORT_REQUEST",
  CLEAN_CLOSED_REPORT_RESPONSE_DATA:
    "farmSell/CLEAN_CLOSED_REPORT_RESPONSE_DATA",
};

export const Creators = {
  resetData: () => ({
    type: Types.RESET_DATA,
    payload: {},
  }),
  setClosedReportRequest: (data) => ({
    type: Types.SET_CLOSED_REPORT_REQUEST,
    payload: { data },
  }),
  setClosedReportData: (data) => ({
    type: Types.SET_CLOSED_REPORT_RESPONSE_DATA,
    payload: { data },
  }),
  cleanClosedReportRequest: (data) => ({
    type: Types.CLEAN_CLOSED_REPORT_REQUEST,
    payload: { data },
  }),
  cleanClosedReportData: (data) => ({
    type: Types.CLEAN_CLOSED_REPORT_RESPONSE_DATA,
    payload: { data },
  }),
};

const INITIAL_STATE = {
  newFarmSell: {
    clientName: null,
    sellDate: null,
    estimatedBilling: 0,
    actualBilling: 0,
    animals: [],
  },
  closeReportRequest: localStorage.getItem("@Bovexo:closeReportRequest")
    ? JSON.parse(localStorage.getItem("@Bovexo:closeReportRequest"))
    : {
        startDate: null,
        endDate: null,
        clientId: null,
        supplierId: null,
        animalGender: "all",
        saleScenarioIds: [],
      },
  closeReportResponseData: localStorage.getItem(
    "@Bovexo:closeReportResponseData"
  )
    ? JSON.parse(localStorage.getItem("@Bovexo:closeReportResponseData"))
    : null,
};

export default function farmSell(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_DATA:
      return INITIAL_STATE;
    case Types.SET_CLOSED_REPORT_REQUEST:
      localStorage.setItem(
        "@Bovexo:closeReportRequest",
        JSON.stringify(action.payload.data)
      );
      return { ...state, closeReportRequest: action.payload.data };
    case Types.SET_CLOSED_REPORT_RESPONSE_DATA:
      localStorage.setItem(
        "@Bovexo:closeReportResponseData",
        JSON.stringify(action.payload.data)
      );
      return { ...state, closeReportResponseData: action.payload.data };
    case Types.CLEAN_CLOSED_REPORT_REQUEST:
      localStorage.removeItem("@Bovexo:closeReportRequest");
      return {
        ...state,
        closeReportRequest: {
          startDate: null,
          endDate: null,
          clientId: null,
          supplierId: null,
          animalGender: "all",
          saleScenarioIds: [],
        },
      };
    case Types.CLEAN_CLOSED_REPORT_RESPONSE_DATA:
      localStorage.removeItem("@Bovexo:closeReportResponseData");
      return { ...state, closeReportResponseData: null };

    default:
      return state;
  }
}
