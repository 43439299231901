import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  border-radius: 5px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  margin-top: 10px;
  background: #fff;
`;

export const Body = styled.div`
  padding: 20px 10px;

  ul > li {
    color: #d44c4c;
  }

  .ant-input {
    width: 65px !important;
  }
`;

export const Footer = styled.div`
  border-top: 1px solid #e8e5e5;
  padding: 20px 10px;
  .buttonsDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .selectDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      font-family: "Asap", sans-serif;
      font-size: 14px;
      font-weight: bold;
      margin-right: 8px;
    }
  }
`;
