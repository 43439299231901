import { Button, Select } from "antd";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Creators as LotActions } from "../../../store/ducks/lot";
import { ContainerSelectLotProduction, CustomSelect } from "./styles";

// import { Container } from './styles';

function SelectLotProduction({
  customSelect = false,
  placeholder = null,
  id,
  name,
  value,
  onDeselect,
  onChange,
  onlyActiveOrPending = true,
}) {
  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
    translation,
  } = useSelector((state) => state.app);

  const { isLotListDrowpDownLoading, lotListDrowpDown } = useSelector(
    (state) => state.lot
  );

  const dispatch = useDispatch();

  const getLotList = useCallback(() => {
    dispatch(LotActions.getLotsProductionDropDown(groupId, farmId));
  }, [dispatch, farmId, groupId]);

  const createNewLot = useCallback(() => {
    dispatch(LotActions.showDrawerLotProduction());
  }, [dispatch]);

  useEffect(() => {
    getLotList();
  }, [getLotList]);

  const SelectLotElement = customSelect ? (
    <CustomSelect
      loading={isLotListDrowpDownLoading}
      name={name}
      id={id}
      value={value || undefined}
      placeholder={
        placeholder ? placeholder : translation.defaultSelectPlaceholder
      }
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      allowClear
      onDeselect={onDeselect}
      onChange={onChange}
    >
      {lotListDrowpDown
        .filter((lot) =>
          onlyActiveOrPending
            ? lot.status === "A" ||
              lot.status === "Active" ||
              lot.status === "P" ||
              lot.status === "Pending"
            : true
        )
        .map((lot) => (
          <Select.Option key={lot.id} value={lot.id}>
            {lot.name}
          </Select.Option>
        ))}
    </CustomSelect>
  ) : (
    <Select
      loading={isLotListDrowpDownLoading}
      name={name}
      id={id}
      value={value || undefined}
      placeholder={
        placeholder ? placeholder : translation.defaultSelectPlaceholder
      }
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      allowClear
      onDeselect={onDeselect}
      onChange={onChange}
    >
      {lotListDrowpDown
        .filter((lot) =>
          onlyActiveOrPending
            ? lot.status === "A" ||
              lot.status === "Active" ||
              lot.status === "P" ||
              lot.status === "Pending"
            : true
        )
        .map((lot) => (
          <Select.Option key={lot.id} value={lot.id}>
            {lot.name}
          </Select.Option>
        ))}
    </Select>
  );

  return (
    <ContainerSelectLotProduction>
      {SelectLotElement}
      <Button shape="circle" icon="plus" onClick={createNewLot} />
    </ContainerSelectLotProduction>
  );
}

export default SelectLotProduction;
