import React, { useCallback } from "react";
import { Creators as ReportActions } from "../../../../../store/ducks/report";
import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "../styles";

const DashboardReproductionBirthTable = ({ dashboardResult }) => {
  const dispatch = useDispatch();
  const { translation } = useSelector((state) => state.app);
  const generateReport = useCallback(
    (ids = []) => {
      if (ids == null || ids.length === 0) {
        return;
      } else {
        dispatch(
          ReportActions.showModal(
            translation.animal.listingReportTitle,
            "animal",
            null,
            ids
          )
        );
      }
    },
    [dispatch, translation.animal.listingReportTitle]
  );
  return (
    <Table>
      <Row type="flex" gutter={8}>
        <Col span={12} className="col">
          <strong>Nascidos Vivos</strong>
        </Col>
        <Col span={12} className="col value">
          <button
            onClick={() => generateReport(dashboardResult?.idsOfCalfAlive)}
          >
            {dashboardResult?.totalOfCalfAlive || 0}
          </button>
        </Col>
      </Row>
      <Row type="flex" gutter={8}>
        <Col span={12} className="col">
          <strong>Machos</strong>
        </Col>
        <Col span={12} className="col value">
          <button
            onClick={() => generateReport(dashboardResult?.idsOfMaleCalf)}
          >
            {dashboardResult?.totalOfMaleCalf || 0}
          </button>
        </Col>
      </Row>
      <Row type="flex" gutter={8}>
        <Col span={12} className="col">
          <strong>Fêmeas</strong>
        </Col>
        <Col span={12} className="col value">
          <button
            onClick={() => generateReport(dashboardResult?.idsOfFemaleCalf)}
          >
            {dashboardResult?.totalOfFemaleCalf || 0}
          </button>
        </Col>
      </Row>
      <Row type="flex" gutter={8}>
        <Col span={12} className="col">
          <strong>Abortos</strong>
        </Col>
        <Col span={12} className="col value">
          <strong>{dashboardResult?.totalOfAbortions || 0}</strong>
        </Col>
      </Row>
      <Row type="flex" gutter={8}>
        <Col span={12} className="col">
          <strong>Morte Embr.</strong>
        </Col>
        <Col span={12} className="col value">
          <strong>{dashboardResult?.totalOfEmbryonicDeath || 0}</strong>
        </Col>
      </Row>
      <Row type="flex" gutter={8}>
        <Col span={12} className="col">
          <strong>Natimortos</strong>
        </Col>
        <Col span={12} className="col value">
          <button
            onClick={() => generateReport(dashboardResult?.idsOfStillbornCalf)}
          >
            {dashboardResult?.totalOfStillbornCalf || 0}
          </button>
        </Col>
      </Row>
    </Table>
  );
};

export default DashboardReproductionBirthTable;
