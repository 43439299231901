import Axios from "axios";
import React, { useEffect, useState } from "react";
/** Redux libs */
import { useDispatch, useSelector } from "react-redux";
import { Creators as GeneralParameterActions } from "../../store/ducks/generalParameters";
/** Components */
import { Col, Row, Spin, notification } from "antd";
import PencilIcon from "../../components/utils/icons/pencil";
import { CardMessage, Container, EditButton, Message, Title } from "./styles";
/** Services */
import {
  getParameterItems,
  saveOrUpdateParameter,
} from "../../services/generalParameterService";
import DisplayMovementAnimalReportParameter from "./display";
import EditMovementAnimalReportParameter from "./edit";

const MovementAnimalReportParameter = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [parameterData, setParameterData] = useState([]);

  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const { parameterBeingEdit } = useSelector(
    (state) => state.generalParameters
  );

  const dispatch = useDispatch();

  function validate(data) {
    if (Number(data.valueExtra) < Number(data.value) + 1) {
      notification.error({
        message: "A porcentagem de Cabeceira deve ser maior que a de Fundo.",
      });
      return false;
    }
    if (Number(data.value) <= 0) {
      notification.error({
        message: "A porcentagem de Fundo não pode ser menor ou igual a 0.",
      });
      return false;
    }
    return true;
  }

  function cancelEdit() {
    setEditing(false);
  }

  function openEdit() {
    setEditing(true);
  }

  async function saveEdit(data) {
    if (validate(data[0])) {
      dispatch(GeneralParameterActions.changeParameterBeingEdited(null));
      setEditing(false);
      setIsLoading(true);
      try {
        for (var i = 0; i < data.length; i++) {
          let parameter = data[i];
          if (parameter.updated) {
            let {
              data: { results },
            } = await saveOrUpdateParameter({
              groupId,
              farmId,
              parameterItemId: parameter.id,
              parameterId: parameter.parameterId,
              edit: !parameter.isDefault,
              body: parameter,
            });
            results.code = parameter.code;
            results.parameterId = parameter.parameterId;
            results.isDefault = false;
            results.updated = false;
            data[i] = results;
          }
        }
        setParameterData(data);
        notification.success({
          message: translation.generalParameters.successParameterUpdated,
        });
      } catch (error) {
        notification.error({
          message: translation.generalParameters.errorParameterUpdated,
        });
      } finally {
        setIsLoading(false);
      }
    }
  }

  useEffect(() => {
    const signal = Axios.CancelToken.source();
    async function fetchData() {
      setIsLoading(true);
      let dataResult = [];
      try {
        let codes = [1009, 1010];
        for (var i = 0; i < codes.length; i++) {
          let code = codes[i];
          let {
            data: { results },
          } = await getParameterItems({ groupId, farmId, code, signal });
          results.isDefault = results.farmId == null && results.groupId == null;
          results.updated = false;
          dataResult.push(results);
        }
        setParameterData(dataResult);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
    return () => {
      signal.cancel();
    };
  }, [groupId, farmId]);

  return (
    <Container>
      <Row type="flex" justify="space-between">
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Title>{translation.movementAnimalReportParameter.title}</Title>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={12} align="right">
          <EditButton
            type="button"
            onClick={openEdit}
            disabled={
              isEditing ||
              (parameterBeingEdit !== null &&
                parameterBeingEdit !== "parameterData")
                ? true
                : false
            }
          >
            {isEditing
              ? `${translation.buttons.editing}...`
              : `${translation.buttons.edit}`}
            <PencilIcon />
          </EditButton>
        </Col>
      </Row>
      {isEditing ? (
        <EditMovementAnimalReportParameter
          data={parameterData}
          onSave={saveEdit}
          onCancel={cancelEdit}
        />
      ) : (
        <Spin spinning={isLoading}>
          {parameterData == null ? (
            <CardMessage>
              <Row type="flex" justify="center" align="middle">
                <Message
                  dangerouslySetInnerHTML={{
                    __html: translation.buttons.clickInEdit,
                  }}
                />
              </Row>
            </CardMessage>
          ) : (
            <DisplayMovementAnimalReportParameter data={parameterData} />
          )}
        </Spin>
      )}
    </Container>
  );
};

export default MovementAnimalReportParameter;
