import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { ANIMAL_FARM_REPRODUCTIVE_PROTOCOL_FORM_INITIAL_STATE } from "../../../contexts/animalFarmReproductiveProtocolContext";
import useAnimalFarmReproductiveProtocolContext from "../../../hooks/useAnimalFarmReproductiveProtocolContext";
import { useHistory } from "react-router-dom";

// Components
import { Col, Row, notification } from "antd";
import ButtonStandard from "../../../components/utils/button";
import {
  ButtonDeselectAllAnimals,
  ButtonProperty,
  ButtonSelectAllAnimals,
  CardGroupAnimals,
  ColSelectAnimals,
  Container,
  CustomDivider,
  Title,
} from "./styles";

import Loading from "../../../components/utils/loading";
import AnimalReproductiveProtocolGroup from "./animalReproductiveProtocolGroup";
import AnimalReproductiveProtocolTable from "./animalsTable";
import DrawerApplyProtocolForAnimalsGroup from "./drawerApplyProtocolForAnimalsGroup";
// Services
import {
  findAllAnimalsForApplyReproductiveProtocol,
  saveApplyReproductiveProtocol,
} from "../../../services/animalFarmReproductiveProtocolService";

const validationSchema = Yup.object({
  groupAnimals: Yup.array()
    .of(
      Yup.object().shape({
        dateApplication: Yup.date().required(),
        reproductiveProtocolId: Yup.string().required(),
        animalsIds: Yup.array().required(),
      })
    )
    .compact((groupAnimals) => groupAnimals.reproductiveProtocolId != null)
    .required(),
});

const AnimalFarmReproductiveProtocolForm = () => {
  const formRef = useRef(null);

  const [isDrawerApplicationVisible, setIsDrawerApplicationVisible] =
    useState(false);
  const [form, setForm] = useState(
    ANIMAL_FARM_REPRODUCTIVE_PROTOCOL_FORM_INITIAL_STATE
  );
  const [totalAnimalsActive, setTotalAnimalsActive] = useState(0);
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);

  // Redux Variable
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const {
    getAnimals,
    selectedAnimalsKeys,
    updatePagination,
    updateSelectedAnimalsKeys,
    switchIsLoadingAnimals,
    updateAnimalsSelectedAndGrouped,
    animalsSelectedAndGrouped,
    fetchData,
  } = useAnimalFarmReproductiveProtocolContext();

  const history = useHistory();

  // Method

  function handleCancel() {
    setForm(ANIMAL_FARM_REPRODUCTIVE_PROTOCOL_FORM_INITIAL_STATE);
    formRef.current.resetForm(
      ANIMAL_FARM_REPRODUCTIVE_PROTOCOL_FORM_INITIAL_STATE
    );
    updateSelectedAnimalsKeys([]);
    updateAnimalsSelectedAndGrouped([]);
    updatePagination({
      page: 0,
      sorter: null,
      filters: null,
      size: 10,
      ids: null,
      tableSorters: null,
      tableFilters: null,
    });
    history.push(`/admin/managements/reproductive`);
  }

  async function handleSubmitForm(values) {
    setIsLoadingRequest(true);
    try {
      await saveApplyReproductiveProtocol({
        groupId: groupId,
        farmId: farmId,
        body: values,
      });
      notification.success({
        message: "Protocolo aplicado com sucesso.",
      });
      fetchData();
      handleCancel();
    } catch (error) {
      if (Object.keys(error).includes("response")) {
        const { response } = error;
        if (Object.keys(response).includes("data")) {
          const {
            data: { code: errorCode },
          } = response;
          if (errorCode === 5033) {
            notification.error({
              message:
                "Atenção! Existe algum(ns) animais que possuem protocolo registrado na mesma data informada.",
            });
          } else {
            notification.error({
              message: "Erro",
              description: "Erro ao aplicar Protocolo",
            });
          }
        }
      } else {
        notification.error({
          message: "Erro",
          description: "Erro ao aplicar Protocolo",
        });
      }
    } finally {
      setIsLoadingRequest(false);
    }
  }

  const handleSelectAll = async () => {
    switchIsLoadingAnimals();
    try {
      let filters = "";
      if (
        animalsSelectedAndGrouped.length > 0 &&
        animalsSelectedAndGrouped.length === totalAnimalsActive
      ) {
        notification.info({
          message: "Todos os animais ativos da fazenda já foram selecionados.",
        });
        return;
      }
      if (animalsSelectedAndGrouped.length > 0) {
        filters = `id not in (${animalsSelectedAndGrouped
          .map((id) => `'${id}'`)
          .join(",")})`;
      }
      const {
        data: { results: ids },
      } = await findAllAnimalsForApplyReproductiveProtocol({
        groupId,
        farmId,
        justIds: false,
        withoutPagination: true,
        filters,
      });
      updateSelectedAnimalsKeys(ids);
      updatePagination({
        page: 0,
        sorter: null,
        filters: null,
        size: 10,
        ids: null,
        tableSorters: null,
        tableFilters: null,
      });
    } catch (error) {
    } finally {
      switchIsLoadingAnimals();
    }
  };

  const handleDeselectAllAnimals = () => {
    updateSelectedAnimalsKeys([]);
  };

  const handleOpenGroupAnimalsToApplyProtocol = () => {
    setIsDrawerApplicationVisible(true);
  };

  const handleSaveGroupAnimalsToApplyProtocol = (formGroupAnimalValue) => {
    const valuesForm = formRef.current.state.values;
    const newGroupAnimals = [...valuesForm.groupAnimals, formGroupAnimalValue];
    formRef.current.setFieldValue("groupAnimals", newGroupAnimals);
    setIsDrawerApplicationVisible(false);
    getAnimals(
      null,
      null,
      newGroupAnimals.flatMap((group) => group.animalsIds)
    );
    updateAnimalsSelectedAndGrouped(
      newGroupAnimals.flatMap((group) => group.animalsIds)
    );
    updateSelectedAnimalsKeys([]);
  };

  const handleCancelGroupAnimalsToApplyProtocol = () => {
    setIsDrawerApplicationVisible(false);
  };

  const handleRemoveGroupAnimalsFromForm = (id) => {
    const valuesForm = formRef.current.state.values;
    const newGroupAnimals = valuesForm.groupAnimals.filter(
      (group) => group.id !== id
    );

    formRef.current.setFieldValue("groupAnimals", newGroupAnimals);
    getAnimals(
      null,
      null,
      newGroupAnimals.flatMap((group) => group.animalsIds)
    );
    updateAnimalsSelectedAndGrouped(
      newGroupAnimals.flatMap((group) => group.animalsIds)
    );
    updateSelectedAnimalsKeys([]);
  };

  // Effects
  useEffect(() => {
    async function fetchAmountAnimalsActive() {
      try {
        const {
          data: {
            meta: { size },
          },
        } = await findAllAnimalsForApplyReproductiveProtocol({
          groupId,
          farmId,
          justIds: true,
          filters: null,
        });
        setTotalAnimalsActive(size);
      } catch (error) {}
    }
    fetchAmountAnimalsActive();
  }, [farmId, groupId]);

  useEffect(() => {
    getAnimals();
  }, [getAnimals]);

  return (
    <Container>
      {isLoadingRequest && <Loading />}
      <Formik
        ref={formRef}
        initialValues={form}
        validationSchema={validationSchema}
        onSubmit={handleSubmitForm}
        render={({ values, errors, submitCount, handleSubmit }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            {/* Page Header */}
            <div className="pageHeader">
              <Row type="flex" justify="start">
                <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                  <Title>
                    {
                      translation.animalFarmReproductiveProtocol.form
                        .titleCreateNew
                    }
                  </Title>
                </Col>
                <Col
                  xs={8}
                  sm={8}
                  md={8}
                  lg={8}
                  xl={8}
                  align="right"
                  className="buttonsCol"
                >
                  <ButtonStandard
                    type="button"
                    buttonType="type4"
                    size="s"
                    onClick={handleCancel}
                  >
                    {translation.buttons.cancel}
                  </ButtonStandard>
                  <ButtonStandard
                    type="submit"
                    buttonType="type1"
                    width="121px"
                    height="35px"
                    padding="5px 10px 5px 10px"
                    disabled={
                      values.groupAnimals == null ||
                      values.groupAnimals.length === 0
                    }
                  >
                    {translation.buttons.save}
                  </ButtonStandard>
                </Col>
              </Row>
            </div>
            {/* Menu buttons */}
            <Row type="flex">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Row
                  type="flex"
                  justify="start"
                  gutter={16}
                  style={{ marginTop: 10 }}
                >
                  <Col span={18} className="buttonsTable">
                    <ButtonSelectAllAnimals
                      type="button"
                      onClick={handleSelectAll}
                    >
                      Selecionar todos os animais
                    </ButtonSelectAllAnimals>
                  </Col>
                  <Col span={6} align="right">
                    <ButtonDeselectAllAnimals
                      type="button"
                      onClick={handleDeselectAllAnimals}
                      icon="delete"
                    >
                      Limpar seleções
                    </ButtonDeselectAllAnimals>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* Main */}
            <Row type="flex" className="pageBody">
              {/* Col with animals table */}
              <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                <ColSelectAnimals>
                  <AnimalReproductiveProtocolTable />
                </ColSelectAnimals>
              </Col>
              {/* Col with lot demembering info */}
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={6}
                xl={6}
                className="columnDememberingInfo"
              >
                <Row type="flex" style={{ marginTop: 10 }}>
                  {translation.lot.production.dismemberLotPage.animalsTotal}
                </Row>
                <Row
                  type="flex"
                  style={{
                    fontSize: 16,
                    fontWeight: "bolder",
                    color: "#FE8D2A",
                  }}
                >
                  <span>{totalAnimalsActive}</span>
                </Row>

                <Row type="flex" style={{ marginTop: 10 }}>
                  {translation.lot.production.dismemberLotPage.animalsSelected}
                </Row>
                <Row
                  type="flex"
                  style={{
                    fontSize: 16,
                    fontWeight: "bolder",
                    color: "#FE8D2A",
                  }}
                >
                  {selectedAnimalsKeys.length}
                </Row>
                <Row
                  type="flex"
                  style={{
                    fontSize: 16,
                    fontWeight: "bolder",
                    color: "#FE8D2A",
                  }}
                >
                  <ButtonProperty
                    shape="round"
                    className={selectedAnimalsKeys.length > 0 ? "enabled" : ""}
                    onClick={handleOpenGroupAnimalsToApplyProtocol}
                    disabled={selectedAnimalsKeys.length === 0}
                  >
                    {
                      translation.animalFarmReproductiveProtocol.form
                        .buttonApply
                    }
                  </ButtonProperty>
                </Row>
                {values.groupAnimals && values.groupAnimals.length > 0 ? (
                  <>
                    <CustomDivider dashed />
                    <CardGroupAnimals
                      title={
                        translation.animalFarmReproductiveProtocol.form
                          .groupTitle
                      }
                    >
                      {values.groupAnimals.map((group) => (
                        <AnimalReproductiveProtocolGroup
                          key={group.id}
                          group={group}
                          removeGroup={handleRemoveGroupAnimalsFromForm}
                        />
                      ))}
                    </CardGroupAnimals>
                  </>
                ) : null}
              </Col>
            </Row>
          </form>
        )}
      />
      <DrawerApplyProtocolForAnimalsGroup
        isVisible={isDrawerApplicationVisible}
        onSave={handleSaveGroupAnimalsToApplyProtocol}
        onCancel={handleCancelGroupAnimalsToApplyProtocol}
      />
    </Container>
  );
};

export default AnimalFarmReproductiveProtocolForm;
