import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Creators as SupplierActions } from "../../../store/ducks/supplier";
import { Creators as SaleScenarioFarmSellsActions } from "../../../store/ducks/farmSell";
import axios from "axios";
import moment from "moment";
import { Col, DatePicker, Modal, notification, Row, Select, Spin } from "antd";
import { Formik } from "formik";
import * as Yup from "yup";

import { Container } from "./styles";
import ButtonStandard from "../../utils/button";
import { getListOfClientsDropDown } from "../../../services/managementService";
import { indexDropDownSaleScenario } from "../../../services/saleScenarioService";
import { useHistory } from "react-router-dom";
import { getResponseFarmSaleReport } from "../../../services/farmSellService";

// Services

const validateSchema = Yup.object().shape({
  startDate: Yup.date().required(),
  endDate: Yup.date().required(),
});

const FarmSalesReportModal = ({ modalVisible, closeModal }) => {
  const formik = useRef();
  const history = useHistory();
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const {
    listDrowpDown: suppliers,
    isLoadingDropDown: isLoadingDropDownSuppliers,
  } = useSelector((state) => state.supplier);
  const { closeReportRequest } = useSelector((state) => state.farmSell);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({
    startDate: null,
    endDate: null,
    clientId: null,
    supplierId: null,
    animalGender: "all",
    saleScenarioIds: [],
  });
  const [clients, setClients] = useState([]);
  const [saleScenarios, setSaleScenarios] = useState([]);

  const signal = axios.CancelToken.source();

  // Effects
  useEffect(() => {
    setForm(closeReportRequest);
  }, [closeReportRequest]);

  useEffect(() => {
    let signal = axios.CancelToken.source();
    async function fetch() {
      setIsLoading(true);
      try {
        dispatch(
          SupplierActions.getDropdownSuppliers(
            groupId,
            farmId,
            "CattleFarmer",
            true
          )
        );
        const {
          data: { results: resultsClient },
        } = await getListOfClientsDropDown({
          groupId,
          farmId,
          onlyActives: true,
        });
        setClients(resultsClient);
        const {
          data: { results: resultsSaleScenarios },
        } = await indexDropDownSaleScenario({
          groupId,
          farmId,
        });
        const filteredOnlyPartialSaledOrSaled = resultsSaleScenarios.filter(
          (ss) =>
            ss.status === "PartialSold" ||
            ss.status === "Sold" ||
            ss.status === "PS" ||
            ss.status === "S" ||
            ss.status === "PartialCanceled" ||
            ss.status === "PC"
        );
        setSaleScenarios(filteredOnlyPartialSaledOrSaled);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    }

    fetch();

    return () => {
      signal.cancel("Cancelou");
    };
  }, [dispatch, farmId, groupId]);

  const handleCancel = useCallback(() => {
    formik.current.resetForm({
      lotId: null,
      dateMove: moment(),
      picketIdOrigin: "",
      picketOrigin: null,
      picketIdDestination: "",
      picketDestination: null,
      pastureOriginCurrentHeight: null,
      pastureDestinationCurrentHeight: null,
    });
    setForm({
      lotId: null,
      dateMove: moment(),
      picketIdOrigin: "",
      picketOrigin: null,
      picketIdDestination: "",
      picketDestination: null,
      pastureOriginCurrentHeight: null,
      pastureDestinationCurrentHeight: null,
    });
    dispatch(SaleScenarioFarmSellsActions.cleanClosedReportData());
    dispatch(SaleScenarioFarmSellsActions.cleanClosedReportRequest());
    closeModal();
    signal.cancel("Cancelou");
  }, [closeModal, dispatch, signal]);

  const handleSubmit = useCallback(
    async (values, actions) => {
      setIsLoading(true);
      try {
        const {
          data: { results },
        } = await getResponseFarmSaleReport({
          groupId,
          farmId,
          body: {
            ...values,
            animalGender:
              values.animalGender === "all" ? null : values.animalGender,
          },
        });
        setIsLoading(false);
        dispatch(SaleScenarioFarmSellsActions.setClosedReportRequest(values));
        dispatch(SaleScenarioFarmSellsActions.setClosedReportData(results));
        history.push(
          "/admin/decisions/scenarios/sales/farms/sales/closed/report",
          { origin: "farmSell" }
        );
      } catch (error) {
        const {
          response: {
            data: {
              results: { message: errorMessage },
            },
          },
        } = error;
        if (errorMessage === "No sales founded!") {
          notification.error({
            message: "Não foram encontradas vendas com os filtros informados.",
          });
        } else {
          notification.error({
            message: "Error interno. Contate o suporte!",
          });
        }
        setIsLoading(false);
      }
    },
    [dispatch, farmId, groupId, history]
  );

  return (
    <Modal
      visible={modalVisible}
      centered
      closable={false}
      footer={null}
      title={translation.closedSalesReport.form.title}
    >
      <Container>
        <Spin spinning={isLoading}>
          {/* Form */}
          <Row type="flex" gutter={8}>
            <Col span={24}>
              <Formik
                ref={formik}
                initialValues={form}
                enableReinitialize={true}
                onSubmit={handleSubmit}
                validationSchema={validateSchema}
                render={({
                  values,
                  errors,
                  submitCount,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit} autoComplete="off">
                    {/* Body */}
                    <div className="body">
                      {Object.entries(errors).length > 0 && submitCount > 0 && (
                        <Row type="flex" justify="center" align="middle">
                          <label className="error">
                            {translation.error.formError}
                          </label>
                        </Row>
                      )}
                      {/* salesPeriod */}
                      <Row type="flex" justify="start" gutter={8}>
                        <Col span={24}>
                          <Row>
                            <label
                              className={
                                (errors.startDate || errors.endDate) &&
                                submitCount > 0
                                  ? "error"
                                  : ""
                              }
                            >
                              {translation.closedSalesReport.form.salesPeriod}*
                            </label>
                          </Row>
                          <Row type="flex" gutter={8} align="middle">
                            <Col span={8}>
                              <DatePicker
                                name="startDate"
                                format={"DD/MM/YYYY"}
                                value={
                                  values.startDate
                                    ? moment(values.startDate)
                                    : undefined
                                }
                                disabledDate={(currentDate) =>
                                  currentDate.isAfter(moment())
                                }
                                allowClear={false}
                                onChange={(date) =>
                                  setFieldValue("startDate", date)
                                }
                              />
                            </Col>
                            <Col span={2}>até</Col>
                            <Col span={8}>
                              <DatePicker
                                name="endDate"
                                format={"DD/MM/YYYY"}
                                value={
                                  values.endDate
                                    ? moment(values.endDate)
                                    : undefined
                                }
                                disabledDate={(currentDate) =>
                                  currentDate.isBefore(values.startDate) ||
                                  currentDate.isAfter(moment())
                                }
                                allowClear={false}
                                onChange={(date) =>
                                  setFieldValue("endDate", date)
                                }
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      {/* clientId & supplierId*/}
                      <Row type="flex" justify="start" gutter={8}>
                        {/* clientId */}
                        <Col span={12}>
                          <Row>
                            <label>
                              {translation.closedSalesReport.form.clientId}
                            </label>
                          </Row>
                          <Row type="flex">
                            <Select
                              name="clientId"
                              value={values.clientId || undefined}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              placeholder={translation.defaultSelectPlaceholder}
                              onChange={(value) =>
                                setFieldValue("clientId", value)
                              }
                            >
                              {clients.map((c) => (
                                <Select.Option value={c.id} key={c.id}>
                                  {c.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Row>
                        </Col>
                        {/* supplierId */}
                        <Col span={12}>
                          <Row>
                            <label>
                              {translation.closedSalesReport.form.supplierId}
                            </label>
                          </Row>
                          <Row type="flex">
                            <Select
                              name="supplierId"
                              value={values.supplierId || undefined}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              placeholder={translation.defaultSelectPlaceholder}
                              loading={isLoadingDropDownSuppliers}
                              onChange={(value) =>
                                setFieldValue("supplierId", value)
                              }
                            >
                              {suppliers.map((c) => (
                                <Select.Option value={c.id} key={c.id}>
                                  {c.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Row>
                        </Col>
                      </Row>
                      {/* saleScenarioId */}
                      <Row type="flex" justify="start" gutter={8}>
                        <Col span={12}>
                          <Row>
                            <label>
                              {
                                translation.closedSalesReport.form
                                  .saleScenarioId
                              }
                            </label>
                          </Row>
                          <Row type="flex">
                            <Select
                              name="saleScenarioIds"
                              mode="multiple"
                              value={values.saleScenarioIds || undefined}
                              placeholder={translation.defaultSelectPlaceholder}
                              onChange={(pValues) => {
                                setFieldValue("saleScenarioIds", pValues);
                              }}
                            >
                              {saleScenarios.map((c) => (
                                <Select.Option value={c.id} key={c.id}>
                                  {c.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Row>
                        </Col>
                        {/* animalGender */}
                        <Col span={12}>
                          <Row>
                            <label>
                              {translation.closedSalesReport.form.animalGender}
                            </label>
                          </Row>
                          <Row type="flex">
                            <Select
                              name="animalGender"
                              value={values.animalGender || undefined}
                              placeholder={translation.defaultSelectPlaceholder}
                              onChange={(pValues) => {
                                setFieldValue("animalGender", pValues);
                              }}
                            >
                              <Select.Option value={"all"}>Todos</Select.Option>
                              <Select.Option value={"Male"}>
                                {translation.animalGender.Male}
                              </Select.Option>
                              <Select.Option value={"Female"}>
                                {translation.animalGender.Female}
                              </Select.Option>
                            </Select>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                    {/* Footer */}
                    <Row type="flex">
                      <Col span={24}>
                        <Row
                          type="flex"
                          justify="end"
                          align="middle"
                          gutter={8}
                        >
                          <Col>
                            <ButtonStandard
                              type="button"
                              buttonType="type4"
                              size="s"
                              onClick={handleCancel}
                            >
                              {translation.buttons.cancel}
                            </ButtonStandard>
                          </Col>
                          <Col>
                            <ButtonStandard
                              type="submit"
                              buttonType="type1"
                              size="s"
                            >
                              {translation.buttons.generate}
                            </ButtonStandard>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </form>
                )}
              />
            </Col>
          </Row>
        </Spin>
      </Container>
    </Modal>
  );
};

export default FarmSalesReportModal;
