import React, { useCallback, useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
/** Redux libs */
import { useSelector } from "react-redux";
/** Components */
import { Col, Divider, Icon, Input, Row } from "antd";
import NumberFormat from "react-number-format";
import ButtonStandard from "../../../components/utils/button";
import { Body, Container, Footer } from "./styles";

const EditMovementAnimalReportParameter = ({ data, onSave, onCancel }) => {
  const { translation } = useSelector((state) => state.app);
  const [parameters, setParameters] = useState(null);
  const [errros, setErrors] = useState([]);
  const [formMale, setFormMale] = useState([]);
  const [formFemale, setFormFemale] = useState([]);

  const validate = useCallback(
    (parameters) => {
      const aErrors = [];
      if (parameters.length === 0) {
        aErrors.push(
          translation.movementAnimalReportParameter.messages
            .errorLenghtEqualZero
        );
      }
      if (
        parameters.filter(
          (parameter) =>
            parameter.value == null || parameter.value === undefined
        ).length > 0
      ) {
        aErrors.push(
          translation.movementAnimalReportParameter.messages
            .errorClassificationWithoutMinAge
        );
      }
      if (
        parameters.filter((parameter) => parameter.valueExtra == null).length >
        1
      ) {
        aErrors.push(
          translation.movementAnimalReportParameter.messages
            .errorClassificationWithManyMaxAgeNull
        );
      }
      if (
        parameters
          .filter((parameter) => parameter.valueExtra && parameter.value)
          .some((parameter) => {
            if (Number(parameter.valueExtra) <= Number(parameter.value)) {
              return true;
            }
            return false;
          })
      ) {
        aErrors.push(
          translation.movementAnimalReportParameter.messages
            .errorClassificationWithMaxAgeLessThanOrEqualToMinAge
        );
      }
      if (
        parameters
          .filter((parameter) => parameter.valueExtra && parameter.value)
          .some((parameter, index) => {
            if (index >= 1) {
              const previousParam = parameters[index - 1];
              if (Number(parameter.value) <= Number(previousParam.valueExtra)) {
                return true;
              }
            }
            return false;
          })
      ) {
        aErrors.push(
          translation.movementAnimalReportParameter.messages
            .errorClassificationWithWrongOrder
        );
      }
      setErrors(aErrors);
      return aErrors.length === 0;
    },
    [
      translation.movementAnimalReportParameter.messages
        .errorClassificationWithManyMaxAgeNull,
      translation.movementAnimalReportParameter.messages
        .errorClassificationWithMaxAgeLessThanOrEqualToMinAge,
      translation.movementAnimalReportParameter.messages
        .errorClassificationWithWrongOrder,
      translation.movementAnimalReportParameter.messages
        .errorClassificationWithoutMinAge,
      translation.movementAnimalReportParameter.messages.errorLenghtEqualZero,
    ]
  );

  const handleRemoveItem = useCallback((gender, index) => {
    if (gender === "male") {
      setFormMale((prev) => prev.filter((item, iIndex) => iIndex !== index));
    } else {
      setFormFemale((prev) => prev.filter((item, iIndex) => iIndex !== index));
    }
  }, []);

  const handleAddItem = useCallback(
    (gender) => {
      if (gender === "male") {
        const lastItem = formMale[formMale.length - 1];
        setFormMale((prev) => [
          ...prev,
          { ...lastItem, id: uuid(), value: null, valueExtra: null },
        ]);
      } else {
        const lastItem = formMale[formFemale.length - 1];
        setFormFemale((prev) => [
          ...prev,
          { ...lastItem, id: uuid(), value: null, valueExtra: null },
        ]);
      }
    },
    [formFemale.length, formMale]
  );

  const handleSave = useCallback(() => {
    const newParamenters = parameters.map((parameter) => {
      if (parameter.code === 1010) {
        parameter.items = formFemale;
      } else if (parameter.code === 1009) {
        parameter.items = formMale;
      }
      parameter.updated = true;
      return parameter;
    });
    if (validate(formMale) && validate(formFemale)) onSave(newParamenters);
  }, [formFemale, formMale, onSave, parameters, validate]);

  useEffect(() => {
    setParameters(data);
    setFormMale(
      data
        .filter((parameter) => parameter.code === 1009)
        .flatMap((parameter) => parameter.items)
        .sort((itemA, itemB) => Number(itemA.value) - Number(itemB.value))
    );
    setFormFemale(
      data
        .filter((parameter) => parameter.code === 1010)
        .flatMap((parameter) => parameter.items)
        .sort((itemA, itemB) => Number(itemA.value) - Number(itemB.value))
    );
  }, [data]);

  return (
    <Container>
      {/* Body */}
      <Body type="flex">
        <Row type="flex" justify="start" gutter={8}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <strong>
              {translation.movementAnimalReportParameter.classification}
            </strong>
          </Col>
        </Row>
        {errros.length > 0 && (
          <Row type="flex" justify="center">
            <ul>
              {errros.map((error, index) => (
                <li key={`error_${index}`}>{error}</li>
              ))}
            </ul>
          </Row>
        )}
        <Divider dashed>{translation.animalGender.Female}</Divider>
        {formFemale.map((item, index) => (
          <Row
            key={index}
            type="flex"
            justify="center"
            align="middle"
            gutter={8}
            style={{ marginBottom: 4 }}
          >
            <Col>
              <NumberFormat
                customInput={Input}
                value={item.value}
                decimalScale={0}
                onValueChange={({ floatValue }) =>
                  setFormFemale((prevForm) =>
                    prevForm.map((pItem, pIndex) => {
                      if (index === pIndex) {
                        pItem.value = floatValue ? String(floatValue) : null;
                      }
                      return pItem;
                    })
                  )
                }
              />
            </Col>
            <Col>
              <i>de</i>
            </Col>
            <Col>
              <NumberFormat
                customInput={Input}
                value={item.valueExtra}
                addonAfter="meses"
                decimalScale={0}
                onValueChange={({ floatValue }) =>
                  setFormFemale((prevForm) =>
                    prevForm.map((pItem, pIndex) => {
                      if (index === pIndex) {
                        pItem.valueExtra = floatValue
                          ? String(floatValue)
                          : null;
                      }
                      return pItem;
                    })
                  )
                }
              />
            </Col>
            {formFemale.length > 1 && (
              <Col>
                <button
                  style={{ all: "unset", cursor: "pointer" }}
                  onClick={() => handleRemoveItem("female", index)}
                >
                  <Icon
                    type="delete"
                    style={{ fontSize: 24, color: "#d44c4c" }}
                  />
                </button>
              </Col>
            )}
          </Row>
        ))}
        <Row
          type="flex"
          justify="center"
          align="middle"
          gutter={8}
          style={{ marginBottom: 8, marginTop: 8 }}
        >
          <Col>
            <button
              style={{ all: "unset", cursor: "pointer" }}
              onClick={() => handleAddItem("female")}
            >
              <Icon type="plus-circle" style={{ fontSize: 32 }} />
            </button>
          </Col>
        </Row>
        <Divider dashed>{translation.animalGender.Male}</Divider>
        {formMale.map((item, index) => (
          <Row
            key={index}
            type="flex"
            justify="center"
            gutter={8}
            style={{ marginBottom: 4 }}
          >
            <Col>
              <NumberFormat
                customInput={Input}
                value={item.value}
                decimalScale={0}
                onValueChange={({ floatValue }) =>
                  setFormMale((prevForm) =>
                    prevForm.map((pItem, pIndex) => {
                      if (index === pIndex) {
                        pItem.value = floatValue ? String(floatValue) : null;
                      }
                      return pItem;
                    })
                  )
                }
              />
            </Col>
            <Col>
              <i>de</i>
            </Col>
            <Col>
              <NumberFormat
                customInput={Input}
                value={item.valueExtra}
                addonAfter="meses"
                decimalScale={0}
                onValueChange={({ floatValue }) =>
                  setFormMale((prevForm) =>
                    prevForm.map((pItem, pIndex) => {
                      if (index === pIndex) {
                        pItem.valueExtra = floatValue
                          ? String(floatValue)
                          : null;
                      }
                      return pItem;
                    })
                  )
                }
              />
            </Col>
            {formMale.length > 1 && (
              <Col>
                <button
                  style={{ all: "unset", cursor: "pointer" }}
                  onClick={() => handleRemoveItem("male", index)}
                >
                  <Icon
                    type="delete"
                    style={{ fontSize: 24, color: "#d44c4c" }}
                  />
                </button>
              </Col>
            )}
          </Row>
        ))}
        <Row
          type="flex"
          justify="center"
          align="middle"
          gutter={8}
          style={{ marginBottom: 8, marginTop: 8 }}
        >
          <Col>
            <button
              style={{ all: "unset", cursor: "pointer" }}
              onClick={() => handleAddItem("male")}
            >
              <Icon type="plus-circle" style={{ fontSize: 32 }} />
            </button>
          </Col>
        </Row>
      </Body>
      {/* Footer */}
      <Footer>
        <Row type="flex" justify="space-between">
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="selectDiv"
          ></Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="buttonsDiv">
            <ButtonStandard
              type="button"
              buttonType="typeWithoutBackground"
              color="#d44c4c"
              isUpperCase={true}
              onClick={onCancel}
            >
              {translation.buttons.cancel}
            </ButtonStandard>

            <ButtonStandard
              type="submit"
              buttonType="typeWithoutBackground"
              color="#684E94"
              isUpperCase={true}
              onClick={handleSave}
            >
              {translation.buttons.save}
            </ButtonStandard>
          </Col>
        </Row>
      </Footer>
    </Container>
  );
};

export default EditMovementAnimalReportParameter;
