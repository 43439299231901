import React, { useEffect, useState } from "react";
/** Components */
import { Col, Divider, Row } from "antd";
import { useSelector } from "react-redux";
import { Container } from "./styles";

const DisplayMovementAnimalReportParameter = ({ data }) => {
  const { translation } = useSelector((state) => state.app);
  const [formMale, setFormMale] = useState([]);
  const [formFemale, setFormFemale] = useState([]);

  useEffect(() => {
    setFormMale(
      data
        .filter((parameter) => parameter.code === 1009)
        .flatMap((parameter) => parameter.items)
        .sort((itemA, itemB) => Number(itemA.value) - Number(itemB.value))
    );
    setFormFemale(
      data
        .filter((parameter) => parameter.code === 1010)
        .flatMap((parameter) => parameter.items)
        .sort((itemA, itemB) => Number(itemA.value) - Number(itemB.value))
    );
  }, [data]);
  return (
    <Container>
      <Row type="flex" justify="start" gutter={8}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <strong>
            {translation.movementAnimalReportParameter.classification}
          </strong>
        </Col>
      </Row>
      <Divider dashed>{translation.animalGender.Female}</Divider>
      {formFemale.map((parameterValue, index) =>
        Number(parameterValue.value) > 0 &&
        parameterValue.valueExtra === null ? (
          <Row key={index} type="flex" justify="center" gutter={8}>
            <Col>
              <strong>{parameterValue.value}+</strong> meses
            </Col>
          </Row>
        ) : (
          <Row key={index} type="flex" justify="center" gutter={8}>
            <Col>
              <strong>{parameterValue.value}</strong>
            </Col>
            <Col>
              <i>de</i>
            </Col>
            <Col>
              <strong>{parameterValue.valueExtra}</strong> meses
            </Col>
          </Row>
        )
      )}
      <Divider dashed>{translation.animalGender.Male}</Divider>
      {formMale.map((parameterValue, index) =>
        Number(parameterValue.value) > 0 &&
        parameterValue.valueExtra === null ? (
          <Row key={index} type="flex" justify="center" gutter={8}>
            <Col>
              <strong>{parameterValue.value}+</strong> meses
            </Col>
          </Row>
        ) : (
          <Row key={index} type="flex" justify="center" gutter={8}>
            <Col>
              <strong>{parameterValue.value}</strong>
            </Col>
            <Col>
              <i>de</i>
            </Col>
            <Col>
              <strong>{parameterValue.valueExtra}</strong> meses
            </Col>
          </Row>
        )
      )}
    </Container>
  );
};

export default DisplayMovementAnimalReportParameter;
