import React, { useCallback, useEffect, useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import moment from "moment";
import { useSelector } from "react-redux";
import useAnimalFarmReproductiveProtocolContext from "../../../../hooks/useAnimalFarmReproductiveProtocolContext";
import { Formik } from "formik";
import * as Yup from "yup";

import { Container, Footer } from "./styles";
import { Col, DatePicker, Row, Select, notification } from "antd";
import ButtonStandard from "../../../../components/utils/button";

// Servicves
import { getLotIndexDropDown } from "../../../../services/lotService";
import { findAll } from "../../../../services/reproductiveProtocolService";
import { findAllDropdown } from "../../../../services/reproductionPeriodService";
import SelectLotProduction from "../../../../components/utils/selectLotProduction";

const DrawerApplyProtocolForAnimalsGroup = ({
  isVisible,
  onSave,
  onCancel,
}) => {
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const { selectedAnimalsKeys } = useAnimalFarmReproductiveProtocolContext();
  const [form, setForm] = useState({
    id: null,
    dateApplication: null,
    reproductiveProtocolId: null,
    lotDestinationId: null,
    reproductionPeriodId: null,
    animalsIds: [],
  });
  const [listReproductiveProcols, setListReproductiveProcols] = useState([]);
  const [listReproductionPeriod, setListReproductionPeriod] = useState([]);
  const formikRef = useRef();
  const validationSchema = Yup.object({
    dateApplication: Yup.date().required(),
    reproductiveProtocolId: Yup.string().required(),
    reproductionPeriodId: Yup.string().required(),
    animalsIds: Yup.array().required(),
  });

  const handleDrawerVisible = (visible) => {
    if (visible) {
      setForm((prevState) => ({
        ...prevState,
        id: uuid(),
        animalsIds: [...selectedAnimalsKeys],
      }));
      formikRef.current.resetForm({
        id: uuid(),
        dateApplication: null,
        reproductiveProtocolId: null,
        reproductionPeriodId: null,
        lotDestinationId: null,
        animalsIds: [...selectedAnimalsKeys],
      });
    }
  };
  const handleSelectReproductionPeriod = useCallback(
    (setFieldValue, date = moment()) => {
      const index = listReproductionPeriod.findIndex((reproductPeriod) =>
        date.isBetween(
          moment(reproductPeriod.startDate),
          moment(reproductPeriod.endDate)
        )
      );
      if (index >= 0) {
        const id = listReproductionPeriod[index].id;
        setFieldValue("reproductionPeriodId", id);
      }
    },
    [listReproductionPeriod]
  );
  const handleCloseDrawer = () => {
    formikRef.current.resetForm({
      dateApplication: null,
      reproductiveProtocolId: null,
      reproductionPeriodId: null,
      lotDestinationId: null,
      animalsIds: [],
    });
    onCancel();
  };
  const handleSubmitForm = (values) => {
    const dateApplication = values.dateApplication;
    const animals = values.animalsIds;

    const indexAnimalWithDateApplicationBeforeBirthday = animals.findIndex(
      (animal) => moment(dateApplication).isBefore(moment(animal.birthday))
    );
    const indexAnimalWithDateApplicationBeforeWeaning = animals.findIndex(
      (animal) =>
        animal.weaningDate &&
        moment(dateApplication).isBefore(moment(animal.weaningDate))
    );

    if (indexAnimalWithDateApplicationBeforeBirthday >= 0) {
      notification.error({
        message: `A data escolhida ${moment(dateApplication).format(
          "DD/MM/YYYY"
        )} é anterior a data de nascimento de algum animal selecionado.`,
      });

      return;
    }
    if (indexAnimalWithDateApplicationBeforeWeaning >= 0) {
      notification.error({
        message: `A data escolhida ${moment(dateApplication).format(
          "DD/MM/YYYY"
        )} é anterior a data de desmama de algum animal selecionado.`,
      });

      return;
    }

    onSave({
      ...values,
      animalsIds: values.animalsIds.map((animal) => animal.id),
    });
  };

  // Effects
  useEffect(() => {
    async function getReproductionPeriod() {
      try {
        const {
          data: { results },
        } = await findAllDropdown({
          groupId,
          farmId,
        });
        setListReproductionPeriod(results);
      } catch (error) {}
    }

    async function getReproductiveProtocols() {
      try {
        const {
          data: { results },
        } = await findAll({
          groupId,
          farmId,
        });
        setListReproductiveProcols(
          results.filter((protocol) => protocol.status === "Active")
        );
      } catch (error) {}
    }

    async function fetch() {
      await Promise.all([getReproductionPeriod(), getReproductiveProtocols()]);
    }

    fetch();
  }, [groupId, farmId]);

  return (
    <Container
      width={700}
      onClose={handleCloseDrawer}
      maskClosable={false}
      visible={isVisible}
      afterVisibleChange={handleDrawerVisible}
    >
      <Formik
        ref={formikRef}
        enableReinitialize={true}
        initialValues={form}
        onSubmit={handleSubmitForm}
        validationSchema={validationSchema}
        render={({
          values,
          errors,
          submitCount,
          setFieldValue,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="drawerForm">
              {errors.length > 0 && (
                <Row type="flex" justify="center" align="middle">
                  <label className="error">{translation.error.formError}</label>
                </Row>
              )}
              {/* dateApplication */}
              <Row type="flex" className="rowLabel">
                <label
                  className={
                    errors.dateApplication && submitCount > 0 ? "error" : ""
                  }
                >
                  {
                    translation.animalFarmReproductiveProtocol.form
                      .applyForAnimalsGroupForm.dateApplication
                  }
                  *
                </label>
              </Row>
              <Row type="flex">
                <DatePicker
                  value={values.dateApplication}
                  format={"DD/MM/YYYY"}
                  placeholder={translation.defaultDatePickerPlaceholder}
                  disabledDate={(current) => moment().isBefore(current)}
                  onChange={(date) => {
                    setFieldValue("dateApplication", date);
                    handleSelectReproductionPeriod(setFieldValue, date);
                  }}
                />
              </Row>
              {/* reproductiveProtocolId && reproductionPeriodId*/}
              <Row type="flex" gutter={8}>
                <Col span={12}>
                  {/* reproductiveProtocolId */}
                  <Row type="flex" className="rowLabel">
                    <label
                      className={
                        errors.reproductiveProtocolId && submitCount > 0
                          ? "error"
                          : ""
                      }
                    >
                      {
                        translation.animalFarmReproductiveProtocol.form
                          .applyForAnimalsGroupForm.reproductiveProtocolId
                      }
                      *
                    </label>
                  </Row>
                  <Row type="flex">
                    <Select
                      value={values.reproductiveProtocolId || undefined}
                      placeholder={translation.defaultSelectPlaceholder}
                      onChange={(value) =>
                        setFieldValue("reproductiveProtocolId", value)
                      }
                    >
                      {listReproductiveProcols.map((procol) => (
                        <Select.Option key={procol.id} value={procol.id}>
                          {procol.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Row>
                </Col>
                <Col span={12}>
                  {/* reproductionPeriodId */}
                  <Row type="flex" className="rowLabel">
                    <label
                      className={
                        errors.reproductionPeriodId && submitCount > 0
                          ? "error"
                          : ""
                      }
                    >
                      {
                        translation.animalFarmReproductiveProtocol.form
                          .applyForAnimalsGroupForm.reproductionPeriodId
                      }
                      *
                    </label>
                  </Row>
                  <Row type="flex">
                    <Select
                      value={values.reproductionPeriodId || undefined}
                      placeholder={translation.defaultSelectPlaceholder}
                      disabled
                    >
                      {listReproductionPeriod.map((period) => (
                        <Select.Option key={period.id} value={period.id}>
                          {period.description}
                        </Select.Option>
                      ))}
                    </Select>
                  </Row>
                </Col>
              </Row>
              {/* lotDestinationId */}
              <Row type="flex" className="rowLabel">
                <label>
                  {
                    translation.animalFarmReproductiveProtocol.form
                      .applyForAnimalsGroupForm.lotDestinationId
                  }
                </label>
              </Row>
              <Row type="flex">
                <SelectLotProduction
                  id="lotDestinationId"
                  name="lotDestinationId"
                  value={
                    values?.lotDestinationId != null
                      ? values?.lotDestinationId
                      : undefined
                  }
                  onlyActiveOrPending
                  onDeselect={() => {
                    setFieldValue("lotDestinationId", null);
                  }}
                  onChange={(value) => setFieldValue("lotDestinationId", value)}
                />
              </Row>
            </div>

            <Footer>
              <Row type="flex">
                <Col span={24} className="buttonsDiv">
                  <ButtonStandard
                    type="button"
                    buttonType="type7"
                    onClick={handleCloseDrawer}
                  >
                    {translation.buttons.cancel}
                  </ButtonStandard>

                  <ButtonStandard type="submit" buttonType="type6">
                    {translation.buttons.save}
                  </ButtonStandard>
                </Col>
              </Row>
            </Footer>
          </form>
        )}
      />
    </Container>
  );
};

export default DrawerApplyProtocolForAnimalsGroup;
