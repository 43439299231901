import { Formik } from "formik";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { INITIAL_DATA_FORM_ANIMAL_DG } from "../../../contexts/animalPregnancyDiagnosisContext";
import useAnimalPregnancyDiagnosisContext from "../../../hooks/useAnimalPregnancyDiagnosisContext";

import {
  Button,
  Col,
  DatePicker,
  Icon,
  Input,
  InputNumber,
  Modal,
  notification,
  Radio,
  Row,
  Select,
  Spin,
  Tooltip,
} from "antd";
import ButtonStandard from "../../../components/utils/button";
import ArrowDownIcon from "../../../components/utils/table/icons/arrows/down";
import ArrowUpIcon from "../../../components/utils/table/icons/arrows/up";
import {
  ButtonSelectCoverage,
  Container,
  CustomCheckBox,
  TableSelectCoverage,
} from "./styles";

import LogoBullGreen from "../../../components/login/logos/logoBullGreen";
import SelectOvarianResult from "./selectOvarianResult";

// Services

import SelectAnimalsModal from "../../../components/modals/selectAnimalsModal";
import SelectLotProduction from "../../../components/utils/selectLotProduction";
import { saveAnimalPregnancyDiagnosis } from "../../../services/animalPregnancyDiagnosisService";
import { getAnimalsByIdentificationHandlingNumber } from "../../../services/animalService";
import { getBreedIndexActive } from "../../../services/breedService";
import { findAllReproductionParemeterFarmByGroupIdAndFarmId } from "../../../services/reproductionParameterFarmService";
import { findAllDropdown } from "../../../services/reproductionPeriodService";
import { handleKeyDownInputNumber } from "../../../utils/keyDownFunctions";
import { validateForm, validateSelectFemale } from "./validations";

const validationSchema = Yup.object().shape({
  animalId: Yup.string().required(),
  diagnosisDate: Yup.string().required(),
  gestationDays: Yup.number()
    .nullable()
    .notRequired()
    .when(["result", "animalCoverageId"], {
      is: (result, animalCoverageId) => result === 1,
      then: Yup.number().required(),
      otherwise: Yup.number().nullable().notRequired(),
    }),
  discardMotive: Yup.string()
    .nullable()
    .notRequired()
    .when(["shouldDiscardFemale"], {
      is: (shouldDiscardFemale) => shouldDiscardFemale === true,
      then: Yup.string().required(),
      otherwise: Yup.string().nullable().notRequired(),
    }),
});

const listDiscardMotives = [
  {
    id: 0,
    value: "Age",
    label: "Idade",
  },
  {
    id: 1,
    value: "ReproductiveProblems",
    label: "Problemas Reprodutivos",
  },
  {
    id: 2,
    value: "IllnessesOrPhysicalProblems",
    label: "Doenças ou Problemas Físicos",
  },
  {
    id: 3,
    value: "BodyCondition",
    label: "Condição corporal",
  },
  {
    id: 4,
    value: "NegativePregnancy",
    label: "Prenhez Negativa",
  },
  {
    id: 5,
    value: "MaternalAbility",
    label: "Habilidade Materna",
  },
];

const AnimalPregnancyDiagnosisForm = () => {
  const formikRef = useRef(null);
  const firstInputRef = useRef(null);
  const [form, setForm] = useState(INITIAL_DATA_FORM_ANIMAL_DG);
  const [shouldCollapse, setShouldCollapse] = useState(false);
  const [breeds, setBreeds] = useState([]);
  const [diagnosisDateControll, setDiagnosisDateControll] = useState(moment());
  const [reproductionParameters, setReproductionParameters] = useState([]);
  const [reproductionPeriods, setReproductionPeriods] = useState([]);
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  const [isModalDefineCoverageOpen, setIsModalDefineCoverageOpen] =
    useState(false);
  const [coverageSelectedId, setCoverageSelectedId] = useState(null);
  const [coverageSelectedGestationDays, setCoverageSelectedGestationDays] =
    useState(null);

  // Search animal fields
  const [selectedFemale, setSelectedFemale] = useState(null);
  const [animals, setAnimals] = useState([]);
  const [isVisibleModalSelectAnimal, setIsVisibleModalSelectAnimal] =
    useState(false);

  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const {
    showCardForm,
    openOrCloseForm,
    data,
    initializeData,
    fetchData: fetchAnimalPregnancyDiagnosisDataList,
  } = useAnimalPregnancyDiagnosisContext();

  const infoRepasseCoverage = useMemo(() => {
    if (
      selectedFemale &&
      selectedFemale?.lastCoverages &&
      selectedFemale?.lastCoverages.length > 1
    ) {
      return selectedFemale?.lastCoverages.find(
        (coverage) => coverage.type === "MC/REPASSE"
      );
    } else {
      return null;
    }
  }, [selectedFemale]);

  const infoIaCoverage = useMemo(() => {
    if (
      selectedFemale &&
      selectedFemale?.lastCoverages &&
      selectedFemale?.lastCoverages.length > 1
    ) {
      return selectedFemale?.lastCoverages.find(
        (coverage) => coverage.type === "IA/IATF"
      );
    } else {
      return null;
    }
  }, [selectedFemale]);

  const maxGestationDaysBreed = useMemo(() => {
    if (selectedFemale && selectedFemale?.breedId) {
      const animalBreed = breeds.find((b) => b.id === selectedFemale?.breedId);
      if (animalBreed) {
        return animalBreed.maxGestationTime ?? 315;
      } else {
        return 315;
      }
    } else {
      return 315;
    }
  }, [breeds, selectedFemale]);

  const maxGestationDays = useMemo(() => {
    if (diagnosisDateControll && selectedFemale) {
      return (
        moment(diagnosisDateControll).diff(
          moment(selectedFemale.lastCoverageDate),
          "days"
        ) + 1
      );
    } else {
      return 315;
    }
  }, [selectedFemale, diagnosisDateControll]);

  function handleSubmitForm(values, actions) {
    if (validateForm(values, selectedFemale)) {
      if (selectedFemale.lastCoverageDiagnosisDate != null) {
        const diffDaysBetweenLastDiagnosisAndNew = moment(
          values.diagnosisDate
        ).diff(moment(selectedFemale.lastCoverageDiagnosisDate), "days");
        // Check if female have a difference of diagnosis between 2 and 15 days
        if (
          diffDaysBetweenLastDiagnosisAndNew >= 2 &&
          diffDaysBetweenLastDiagnosisAndNew <= 15
        ) {
          Modal.confirm({
            width: 700,
            centered: true,
            icon: (
              <Icon
                type="exclamation-circle"
                style={{
                  color: "#faad14",
                  fontSize: 36,
                }}
              />
            ),
            content: (
              <h1>{`Fêmea possui Diagnóstico de Gestação realizado a ${diffDaysBetweenLastDiagnosisAndNew} dias. Deseja continuar?`}</h1>
            ),
            okText: "Sim",
            cancelText: "Não",
            onOk: () => {
              handleSave(values);
            },
            onCancel: () => {
              return;
            },
          });
        } else {
          handleSave(values);
        }
      } else {
        handleSave(values);
      }
    }
  }

  const handleSave = async (values) => {
    setIsLoadingRequest(true);
    try {
      let body =
        values.result === 1
          ? { ...values, shouldDiscardFemale: false, discardMotive: null }
          : { ...values, gestationDays: null, birthForecast: null };
      await saveAnimalPregnancyDiagnosis({
        groupId,
        farmId,
        body,
      });
      notification.success({
        message: "Diagnóstico de Gestação salvo com sucesso!",
      });
      initializeData({
        ...INITIAL_DATA_FORM_ANIMAL_DG,
        diagnosisDate: values.diagnosisDate,
        shouldDiscardFemale: values.shouldDiscardFemale,
        discardMotive: values.discardMotive,
        lotDestinationId: values.lotDestinationId,
      });
      formikRef.current.resetForm({
        ...INITIAL_DATA_FORM_ANIMAL_DG,
        diagnosisDate: values.diagnosisDate,
        shouldDiscardFemale: values.shouldDiscardFemale,
        discardMotive: values.discardMotive,
        lotDestinationId: values.lotDestinationId,
      });
      setDiagnosisDateControll(values.diagnosisDate);
      setSelectedFemale(null);
      fetchAnimalPregnancyDiagnosisDataList();
    } catch (error) {
      notification.error({
        message:
          translation.handlingReproductionCoverage.errorsAndMessages
            .errorInternalError,
      });
    } finally {
      setIsLoadingRequest(false);
    }
  };

  const handleCancel = async () => {
    formikRef.current.resetForm({});
    initializeData(null);
    openOrCloseForm();
  };

  const calculateNewLastCoverageDate = useCallback((dgDate, days) => {
    return moment(dgDate).subtract(days, "days");
  }, []);

  const calculateBirthForecast = useCallback(
    (female, lastCoverage) => {
      const selectedFemaleBreed = breeds.find((b) => b.id === female?.breedId);
      const newBirthForecast = moment(lastCoverage).add(
        selectedFemaleBreed?.medGestationTime,
        "days"
      );
      return newBirthForecast;
    },
    [breeds]
  );

  const calculateGestationDays = useCallback((dgDate, compareDate) => {
    const daysBetweeLastCoverageAndDiagnosisDate = moment(dgDate).diff(
      moment(compareDate),
      "days"
    );
    const gestationDays = daysBetweeLastCoverageAndDiagnosisDate + 1;
    return gestationDays;
  }, []);

  const handleChangeResult = useCallback((result, setFieldValue) => {
    setFieldValue("result", result);
    if (result === 1) {
      setFieldValue("shouldDiscardFemale", false);
      setFieldValue("discardMotive", null);
    }
  }, []);

  const handleChangeDgDate = useCallback(
    (date, setFieldValue) => {
      setFieldValue("diagnosisDate", date);
      setDiagnosisDateControll(date);
      if (selectedFemale && formikRef) {
        const formikValue = formikRef.current.state.values;
        const female = selectedFemale;

        let newGestationDays = formikValue.gestationDays;
        if (formikValue.result === 1 && date) {
          // If Female have lastCoverage and is prenha or servida
          if (
            female.lastCoverageDate != null &&
            female.femaleSituation !== "vazia"
          ) {
            newGestationDays = calculateGestationDays(
              date,
              female.lastCoverageDate
            );
            setFieldValue("gestationDays", newGestationDays);
          }
          const newLastCoverageDate = calculateNewLastCoverageDate(
            date,
            newGestationDays
          );
          const newBirthForecast = calculateBirthForecast(
            female,
            newLastCoverageDate
          );
          setFieldValue("birthForecast", newBirthForecast);

          if (female.lastCoverages > 1) {
            setFieldValue("animalCoverageId", null);
            setCoverageSelectedId(null);
            setCoverageSelectedGestationDays(null);
          }
        }
      }
    },
    [
      selectedFemale,
      calculateNewLastCoverageDate,
      calculateBirthForecast,
      calculateGestationDays,
    ]
  );

  const handleChangeMCGestationDays = useCallback(
    (value, setFieldValue) => {
      const formikValue = formikRef.current.state.values;
      const female = selectedFemale;
      setFieldValue("gestationDays", value ?? 1);
      if (value) {
        const newLastCoverageDate = calculateNewLastCoverageDate(
          formikValue.diagnosisDate,
          value
        );
        const newBirthForecast = calculateBirthForecast(
          female,
          newLastCoverageDate
        );
        setFieldValue("birthForecast", newBirthForecast);
      }
      setCoverageSelectedGestationDays(value ?? 1);
    },
    [calculateBirthForecast, calculateNewLastCoverageDate, selectedFemale]
  );

  const handleModalConfirm = useCallback(() => {
    const formikValue = formikRef.current.state.values;
    const diagnosisDate = formikValue.diagnosisDate;
    if (
      selectedFemale &&
      selectedFemale?.lastCoverages.find(
        (coverage) => coverage.id === coverageSelectedId
      ).type === "MC/REPASSE" &&
      coverageSelectedGestationDays === null
    ) {
      notification.error({
        message: 'Informe um valor para o campo "Gestação(dias)" ',
      });
    } else {
      const setFieldValue = formikRef.current.setFieldValue;

      setFieldValue("animalCoverageId", coverageSelectedId);

      // Set data about gestation days and birthForecast
      if (
        selectedFemale &&
        selectedFemale.lastCoverages.find(
          (coverage) => coverage.id === coverageSelectedId
        ).type === "MC/REPASSE"
      ) {
        setFieldValue("gestationDays", coverageSelectedGestationDays);
        // Calculate new Coverage Date, based on input of MC/REPASSE gestationDays
        if (coverageSelectedGestationDays) {
          const newLastCoverageDate = calculateNewLastCoverageDate(
            diagnosisDate,
            coverageSelectedGestationDays
          );
          const newBirthForecast = calculateBirthForecast(
            selectedFemale,
            newLastCoverageDate
          );
          setFieldValue("birthForecast", newBirthForecast);
        }
      } else {
        if (infoIaCoverage && infoRepasseCoverage && formikValue) {
          // Calculate new birthForecast for IA/IATF
          const iaCoverageGestationDays = calculateGestationDays(
            diagnosisDate,
            infoIaCoverage?.coverageDate
          );
          setFieldValue("gestationDays", iaCoverageGestationDays);
          setCoverageSelectedGestationDays(iaCoverageGestationDays);
          const newBirthForecast = calculateBirthForecast(
            selectedFemale,
            infoIaCoverage?.coverageDate
          );
          setFieldValue("birthForecast", newBirthForecast);
        }
      }

      setIsModalDefineCoverageOpen(false);
    }
  }, [
    calculateBirthForecast,
    calculateGestationDays,
    calculateNewLastCoverageDate,
    coverageSelectedGestationDays,
    coverageSelectedId,
    infoIaCoverage,
    infoRepasseCoverage,
    selectedFemale,
  ]);

  const handleModalCancel = useCallback(() => {
    setIsModalDefineCoverageOpen(false);
  }, []);

  /** Methods about search animal */

  const handleDeselectFemale = useCallback((setFieldValue) => {
    setFieldValue("animalId", null);
    setFieldValue("animalHandlingNumber", null);
    setSelectedFemale(null);
  }, []);

  const handleAfterSelectFemale = useCallback(
    (formikValue, female, setFieldValue) => {
      // If female has last coverage and is 'prenha' or 'servida'
      if (
        female?.lastCoverageDate != null &&
        female.femaleSituation !== "vazia"
      ) {
        const gestationDays = calculateGestationDays(
          formikValue.diagnosisDate,
          female.lastCoverageDate
        );
        setFieldValue("gestationDays", gestationDays);
        const newBirthForecast = calculateBirthForecast(
          female,
          female.lastCoverageDate
        );
        setFieldValue("birthForecast", newBirthForecast);
        if (female.lastCoverages?.length === 1) {
          setFieldValue("animalCoverageId", female.lastCoverageId);
        } else {
          if (
            female.lastCoverageDiagnosisResult != null &&
            female.lastCoverageId != null
          ) {
            setFieldValue("animalCoverageId", female.lastCoverageId);
          } else {
            setFieldValue("animalCoverageId", null);
          }
        }
      } else {
        setFieldValue("gestationDays", null);
        setFieldValue("birthForecast", null);
        setFieldValue("animalCoverageId", null);
      }
      setFieldValue("result", null);
      setFieldValue("shouldDiscardFemale", false);
      setFieldValue("discardMotive", null);
    },
    [calculateBirthForecast, calculateGestationDays]
  );

  const handleErrorWhenSelectFemale = useCallback(
    (error, formikValue, female, setFieldValue) => {
      const periodAfterCoverage = reproductionParameters
        ? Number(reproductionParameters["TEMPO_APOS_COBERTURA_PARA_DG"])
        : 0;
      const femaleAge = moment().diff(moment(female?.birthday), "months");
      const ageForReproduction = reproductionParameters
        ? Number(reproductionParameters["IDADE_INICIO_FASE_REPRODUTIVA"])
        : 0;
      switch (error) {
        case "DG-CHECK-FEMALE-FATTEN":
          Modal.confirm({
            width: 700,
            centered: true,
            icon: (
              <Icon
                type="exclamation-circle"
                style={{
                  color: "#faad14",
                  fontSize: 36,
                }}
              />
            ),
            content: <h1>Fêmea de finalidade Engorda, deseja continuar?</h1>,
            okText: "Sim",
            cancelText: "Não",
            onOk: () => {
              // Check if will change female function
              Modal.confirm({
                width: 700,
                centered: true,
                icon: (
                  <Icon
                    type="exclamation-circle"
                    style={{
                      color: "#faad14",
                      fontSize: 36,
                    }}
                  />
                ),
                content: <h1>Deseja alterar a fêmea para Reprodução?</h1>,
                okText: "Sim",
                cancelText: "Não",
                onOk: () => {
                  setFieldValue(
                    "shouldChangeFemaleCategoryForReproduction",
                    true
                  );
                  // Check if female is "VAZIA"
                  if (
                    selectedFemale?.femaleSituation === "vazia" ||
                    selectedFemale?.femaleSituation === null
                  ) {
                    Modal.confirm({
                      width: 700,
                      centered: true,
                      icon: (
                        <Icon
                          type="exclamation-circle"
                          style={{
                            color: "#faad14",
                            fontSize: 36,
                          }}
                        />
                      ),
                      content: (
                        <h1>
                          Fêmea vazia. Deseja fazer o Diagnóstico de Gestação?
                        </h1>
                      ),
                      okText: "Sim",
                      cancelText: "Não",
                      onOk: () => {
                        if (femaleAge < ageForReproduction) {
                          Modal.confirm({
                            width: 700,
                            centered: true,
                            icon: (
                              <Icon
                                type="exclamation-circle"
                                style={{
                                  color: "#faad14",
                                  fontSize: 36,
                                }}
                              />
                            ),
                            content: (
                              <h1>
                                Fêmea não possui idade para início da fase
                                reprodutiva, deseja continuar?
                              </h1>
                            ),
                            okText: "Sim",
                            cancelText: "Não",
                            onOk: () => {
                              handleAfterSelectFemale(
                                formikValue,
                                female,
                                setFieldValue
                              );
                            },
                            onCancel: () => {
                              handleDeselectFemale(setFieldValue);
                            },
                          });
                        } else {
                          handleAfterSelectFemale(
                            formikValue,
                            female,
                            setFieldValue
                          );
                        }
                      },
                      onCancel: () => {
                        handleDeselectFemale(setFieldValue);
                      },
                    });
                  } else {
                    // Check if female have a last coverage diff amount days from now
                    const diffBetweenNowAndLastCoverage = moment().diff(
                      moment(selectedFemale?.lastCoverageDate),
                      "days"
                    );
                    if (diffBetweenNowAndLastCoverage < periodAfterCoverage) {
                      Modal.confirm({
                        width: 700,
                        centered: true,
                        icon: (
                          <Icon
                            type="exclamation-circle"
                            style={{
                              color: "#faad14",
                              fontSize: 36,
                            }}
                          />
                        ),
                        content: (
                          <h1>
                            Tempo pós cobertura, para registro do Diagnóstico de
                            Gestação, menor que o definido nos parâmetros
                            reprodutivos. Deseja continuar?
                          </h1>
                        ),
                        okText: "Sim",
                        cancelText: "Não",
                        onOk: () => {
                          handleAfterSelectFemale(
                            formikValue,
                            female,
                            setFieldValue
                          );
                        },
                        onCancel: () => {
                          handleDeselectFemale(setFieldValue);
                        },
                      });
                    } else {
                      handleAfterSelectFemale(
                        formikValue,
                        female,
                        setFieldValue
                      );
                    }
                  }
                },
                onCancel: () => {
                  setFieldValue(
                    "shouldChangeFemaleCategoryForReproduction",
                    false
                  );
                  // Check if female is "VAZIA"
                  if (
                    selectedFemale?.femaleSituation === "vazia" ||
                    selectedFemale?.femaleSituation === null
                  ) {
                    Modal.confirm({
                      width: 700,
                      centered: true,
                      icon: (
                        <Icon
                          type="exclamation-circle"
                          style={{
                            color: "#faad14",
                            fontSize: 36,
                          }}
                        />
                      ),
                      content: (
                        <h1>
                          Fêmea vazia. Deseja fazer o Diagnóstico de Gestação?
                        </h1>
                      ),
                      okText: "Sim",
                      cancelText: "Não",
                      onOk: () => {
                        if (femaleAge < ageForReproduction) {
                          Modal.confirm({
                            width: 700,
                            centered: true,
                            icon: (
                              <Icon
                                type="exclamation-circle"
                                style={{
                                  color: "#faad14",
                                  fontSize: 36,
                                }}
                              />
                            ),
                            content: (
                              <h1>
                                Fêmea não possui idade para início da fase
                                reprodutiva, deseja continuar?
                              </h1>
                            ),
                            okText: "Sim",
                            cancelText: "Não",
                            onOk: () => {
                              handleAfterSelectFemale(
                                formikValue,
                                female,
                                setFieldValue
                              );
                            },
                            onCancel: () => {
                              handleDeselectFemale(setFieldValue);
                            },
                          });
                        } else {
                          handleAfterSelectFemale(
                            formikValue,
                            female,
                            setFieldValue
                          );
                        }
                      },
                      onCancel: () => {
                        handleDeselectFemale(setFieldValue);
                      },
                    });
                  } else {
                    // Check if female have a last coverage diff amount days from now
                    const diffBetweenNowAndLastCoverage = moment().diff(
                      moment(selectedFemale?.lastCoverageDate),
                      "days"
                    );
                    if (diffBetweenNowAndLastCoverage < periodAfterCoverage) {
                      Modal.confirm({
                        width: 700,
                        centered: true,
                        icon: (
                          <Icon
                            type="exclamation-circle"
                            style={{
                              color: "#faad14",
                              fontSize: 36,
                            }}
                          />
                        ),
                        content: (
                          <h1>
                            Tempo pós cobertura, para registro do Diagnóstico de
                            Gestação, menor que o definido nos parâmetros
                            reprodutivos. Deseja continuar?
                          </h1>
                        ),
                        okText: "Sim",
                        cancelText: "Não",
                        onOk: () => {
                          setFieldValue(
                            "shouldChangeFemaleCategoryForReproduction",
                            false
                          );
                          handleAfterSelectFemale(
                            formikValue,
                            female,
                            setFieldValue
                          );
                        },
                        onCancel: () => {
                          handleDeselectFemale(setFieldValue);
                        },
                      });
                    } else {
                      setFieldValue(
                        "shouldChangeFemaleCategoryForReproduction",
                        false
                      );
                      handleAfterSelectFemale(
                        formikValue,
                        female,
                        setFieldValue
                      );
                    }
                  }
                },
              });
            },
            onCancel: () => {
              handleDeselectFemale(setFieldValue);
            },
          });
          break;
        case "DG-CHECK-FEMALE-VAZIA":
          Modal.confirm({
            width: 700,
            centered: true,
            icon: (
              <Icon
                type="exclamation-circle"
                style={{
                  color: "#faad14",
                  fontSize: 36,
                }}
              />
            ),
            content: (
              <h1>Fêmea vazia. Deseja fazer o Diagnóstico de Gestação?</h1>
            ),
            okText: "Sim",
            cancelText: "Não",
            onOk: () => {
              setFieldValue("shouldChangeFemaleCategoryForReproduction", false);
              if (femaleAge < ageForReproduction) {
                Modal.confirm({
                  width: 700,
                  centered: true,
                  icon: (
                    <Icon
                      type="exclamation-circle"
                      style={{
                        color: "#faad14",
                        fontSize: 36,
                      }}
                    />
                  ),
                  content: (
                    <h1>
                      Fêmea não possui idade para início da fase reprodutiva,
                      deseja continuar?
                    </h1>
                  ),
                  okText: "Sim",
                  cancelText: "Não",
                  onOk: () => {
                    handleAfterSelectFemale(formikValue, female, setFieldValue);
                  },
                  onCancel: () => {
                    handleDeselectFemale(setFieldValue);
                  },
                });
              } else {
                handleAfterSelectFemale(formikValue, female, setFieldValue);
              }
            },
            onCancel: () => {
              handleDeselectFemale(setFieldValue);
            },
          });
          break;
        case "DG-CHECK-FEMALE-LAST-COVERAGE-TIME":
          Modal.confirm({
            width: 700,
            centered: true,
            icon: (
              <Icon
                type="exclamation-circle"
                style={{
                  color: "#faad14",
                  fontSize: 36,
                }}
              />
            ),
            content: (
              <h1>
                Tempo pós cobertura, para registro do Diagnóstico de Gestação,
                menor que o definido nos parâmetros reprodutivos. Deseja
                continuar?
              </h1>
            ),
            okText: "Sim",
            cancelText: "Não",
            onOk: () => {
              setFieldValue("shouldChangeFemaleCategoryForReproduction", false);
              handleAfterSelectFemale(formikValue, female, setFieldValue);
            },
            onCancel: () => {
              handleDeselectFemale(setFieldValue);
            },
          });
          break;
        default:
          setFieldValue("shouldChangeFemaleCategoryForReproduction", false);
          handleAfterSelectFemale(formikValue, female, setFieldValue);
          break;
      }
    },
    [
      handleAfterSelectFemale,
      handleDeselectFemale,
      selectedFemale,
      reproductionParameters,
    ]
  );

  const handleConfirmSelectionAnimal = useCallback(
    async (keys) => {
      const formikValue = formikRef.current.state.values;
      const setFieldValue = formikRef.current.setFieldValue;
      const animalIndex = animals.findIndex((animal) => animal.id === keys[0]);
      const animal = animals[animalIndex];
      if (animal) {
        setSelectedFemale(animal);
        setFieldValue("animalId", animal?.id);
        setFieldValue("animalHandlingNumber", animal?.handlingNumber);

        try {
          await validateSelectFemale(animal, reproductionParameters);
          handleAfterSelectFemale(formikValue, animal, setFieldValue);
        } catch (error) {
          handleErrorWhenSelectFemale(
            error,
            formikValue,
            animal,
            setFieldValue
          );
        }
      }
      setIsVisibleModalSelectAnimal(false);
    },
    [
      animals,
      handleAfterSelectFemale,
      handleErrorWhenSelectFemale,
      reproductionParameters,
    ]
  );

  const handleCancelSelectionAnimal = useCallback(() => {
    setIsVisibleModalSelectAnimal(false);
  }, []);

  const handleFindAnimal = useCallback(
    async (identification) => {
      const setFieldValue = formikRef.current.setFieldValue;
      const setFieldError = formikRef.current.setFieldError;
      if (!identification) {
        notification.warn({
          message: "Infome um valor no campo Identificação",
        });
        return;
      }
      setIsLoadingRequest(true);
      try {
        const {
          data: { results },
        } = await getAnimalsByIdentificationHandlingNumber({
          groupId,
          farmId,
          identification,
          specificGender: "Female",
        });
        if (results.length === 0) {
          notification.error({
            message: "Nenhum animal encontrado!",
          });
          setFieldError("animalId", "Animal não encontrado");
        } else if (results.length > 1) {
          setAnimals(results);
          setIsVisibleModalSelectAnimal(true);
        } else {
          const female = results[0];
          const formikValue = formikRef.current.state.values;
          setSelectedFemale(female);
          setFieldValue("matrizAnimalId", female?.id);
          setFieldValue("animalId", female?.id);
          try {
            await validateSelectFemale(female, reproductionParameters);
            setFieldValue("shouldChangeFemaleCategoryForReproduction", false);
            handleAfterSelectFemale(formikValue, female, setFieldValue);
          } catch (error) {
            handleErrorWhenSelectFemale(
              error,
              formikValue,
              female,
              setFieldValue
            );
          }
        }
      } catch (error) {
        console.error(error);
        notification.error({
          message: "Nenhum animal encontrado!",
        });
        setFieldError("animalId", "Animal não encontrado");
      } finally {
        setIsLoadingRequest(false);
      }
    },
    [
      farmId,
      groupId,
      handleAfterSelectFemale,
      handleErrorWhenSelectFemale,
      reproductionParameters,
    ]
  );

  // Get data of dropdowns
  useEffect(() => {
    async function fetchReproductionPeriods() {
      try {
        const {
          data: { results },
        } = await findAllDropdown({ groupId, farmId });
        setReproductionPeriods(results);
      } catch (error) {
        console.error(error);
      }
    }
    async function fetchBreeds() {
      const {
        data: { results },
      } = await getBreedIndexActive({
        groupId,
        farmId,
        withoutBreedCurveParam: true,
      });

      setBreeds(results);
    }
    async function fetchReproductionParameters() {
      try {
        const {
          data: { results },
        } = await findAllReproductionParemeterFarmByGroupIdAndFarmId({
          groupId,
          farmId,
        });
        if (results) {
          let newReproductionParameters = {};
          results.farmValues.forEach((fc) => {
            newReproductionParameters = {
              ...newReproductionParameters,
              [fc.reproductionParameter.name]: fc.value,
            };
          });
          setReproductionParameters(newReproductionParameters);
        }
      } catch (error) {
        console.error(error);
      }
    }

    async function fetchAll() {
      setIsLoadingRequest(true);
      await Promise.all([
        fetchReproductionPeriods(),
        fetchBreeds(),
        fetchReproductionParameters(),
      ]);
      setIsLoadingRequest(false);
    }
    if (showCardForm === true) {
      fetchAll();
      firstInputRef.current.focus();
      setForm(data);
    }
  }, [showCardForm, groupId, farmId, data]);

  return (
    <Container
      title={
        <Row type="flex" justify="center">
          <strong>
            {form.id != null ? "Editar lançamento" : "Novo lançamento"}
          </strong>
        </Row>
      }
    >
      <Formik
        ref={formikRef}
        initialValues={form}
        initialErrors={{}}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={handleSubmitForm}
        render={({
          values,
          setFieldValue,
          errors,
          submitCount,
          handleSubmit,
        }) => (
          <Spin spinning={isLoadingRequest}>
            {!shouldCollapse && (
              <form autoComplete="off" onSubmit={handleSubmit}>
                {Object.entries(errors).length > 0 && submitCount > 0 && (
                  <>
                    <Row type="flex" justify="center" align="middle">
                      <label className="error">
                        {translation.error.formError}
                      </label>
                    </Row>
                  </>
                )}
                {/* Body */}
                {/* Fields diagnosisDate, animalId, and animal Data */}
                <Row type="flex" gutter={8}>
                  <Col span={6}>
                    <Row>
                      <label
                        className={
                          errors.diagnosisDate && submitCount > 0 ? "error" : ""
                        }
                      >
                        {
                          translation.animalPregnancyDiagnosis.form
                            .diagnosisDate
                        }
                        *
                      </label>
                    </Row>
                    <Row>
                      <DatePicker
                        ref={firstInputRef}
                        value={values.diagnosisDate || undefined}
                        format="DD/MM/YYYY"
                        placeholder={translation.defaultDatePickerPlaceholder}
                        allowClear={false}
                        disabledDate={(current) =>
                          selectedFemale?.lastCoverageDate != null
                            ? moment(selectedFemale?.lastCoverageDate).isAfter(
                                current
                              ) || moment().isBefore(current)
                            : moment().isBefore(current)
                        }
                        onChange={(date) =>
                          handleChangeDgDate(date, setFieldValue)
                        }
                      />
                    </Row>
                  </Col>
                  <Col span={6}>
                    <Row>
                      <label
                        className={
                          errors.animalId && submitCount > 0 ? "error" : ""
                        }
                      >
                        {translation.animalPregnancyDiagnosis.form.animalId}*
                      </label>
                    </Row>
                    <Row type="flex" align="middle" gutter={16}>
                      <Col span={20}>
                        <Input
                          ref={firstInputRef}
                          value={values.animalHandlingNumber ?? undefined}
                          placeholder={translation.defaultPlaceholder}
                          disabled={values.id != null}
                          onPressEnter={(e) => {
                            e.preventDefault();
                            handleFindAnimal(values.animalHandlingNumber);
                          }}
                          onChange={(e) =>
                            setFieldValue(
                              "animalHandlingNumber",
                              e.target.value
                            )
                          }
                        />
                      </Col>
                      <Col>
                        <Tooltip title="Digite uma identificação para liberar o botão de pesquisa">
                          <Button
                            disabled={
                              values.id != null ||
                              !values.animalHandlingNumber ||
                              values.animalHandlingNumber.trim().length === 0
                            }
                            shape="circle"
                            icon="search"
                            onClick={() =>
                              handleFindAnimal(values.animalHandlingNumber)
                            }
                          />
                        </Tooltip>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={6}>
                    <Row>
                      <label>
                        {
                          translation.animalPregnancyDiagnosis.form
                            .animalReproductionCategory
                        }
                      </label>
                    </Row>
                    <Row>
                      <strong>
                        {selectedFemale?.animalReproductionCategory ===
                        "nulipara"
                          ? "Nulípara"
                          : selectedFemale?.animalReproductionCategory ===
                            "novilha"
                          ? "Novilha"
                          : selectedFemale?.animalReproductionCategory ===
                            "primipara"
                          ? "Primípara"
                          : selectedFemale?.animalReproductionCategory ===
                            "secundipara"
                          ? "Secundípara"
                          : selectedFemale?.animalReproductionCategory ===
                            "multipara"
                          ? "Multípara"
                          : selectedFemale?.animalReproductionCategory ===
                            "reprodutor"
                          ? "Reprodutor"
                          : "-"}
                      </strong>
                    </Row>
                  </Col>
                  <Col span={6}>
                    <Row>
                      <label>
                        {
                          translation.animalPregnancyDiagnosis.form
                            .femaleSituation
                        }
                      </label>
                    </Row>
                    <Row>
                      <strong>{`${
                        selectedFemale?.gender === "M"
                          ? ""
                          : selectedFemale?.femaleSituation === "vazia"
                          ? "Vazia"
                          : selectedFemale?.femaleSituation === "servida"
                          ? "Servida"
                          : selectedFemale?.femaleSituation === "prenha"
                          ? "Prenha"
                          : ""
                      } ${
                        selectedFemale?.animalReproductionCategory !==
                        "nulipara"
                          ? `(${
                              selectedFemale?.gender === "M"
                                ? ""
                                : selectedFemale?.femaleSituation ===
                                    "servida" &&
                                  selectedFemale?.lastCoverageDate != null
                                ? `${
                                    moment(values.diagnosisDate).diff(
                                      moment(selectedFemale?.lastCoverageDate),
                                      "days"
                                    ) + 1 || 0
                                  } dias`
                                : selectedFemale?.femaleSituation === "vazia" &&
                                  selectedFemale?.lastCoverageDiagnosisDate !=
                                    null
                                ? `${
                                    moment(values.diagnosisDate).diff(
                                      moment(
                                        selectedFemale?.lastCoverageDiagnosisDate
                                      ),
                                      "days"
                                    ) + 1 || 0
                                  } dias`
                                : selectedFemale?.femaleSituation === "vazia" &&
                                  selectedFemale?.lastCoverageDiagnosisDate ===
                                    null
                                ? `${
                                    moment(values.diagnosisDate).diff(
                                      moment(selectedFemale?.acquisitionDate),
                                      "days"
                                    ) + 1 || 0
                                  } dias`
                                : selectedFemale?.femaleSituation ===
                                    "prenha" &&
                                  selectedFemale?.lastCoverageDiagnosisDate !=
                                    null
                                ? `${
                                    moment(values.diagnosisDate).diff(
                                      moment(
                                        selectedFemale?.lastCoverageDiagnosisDate
                                      ),
                                      "days"
                                    ) + 1 || 0
                                  } dias`
                                : ""
                            })`
                          : ""
                      } `}</strong>
                    </Row>
                  </Col>
                </Row>
                {/* Row With Last Covarege data and Lot Original/Destination */}
                <Row type="flex" gutter={8}>
                  <Col span={6}>
                    <Row>
                      <label>
                        {translation.animalPregnancyDiagnosis.form.lastCoverage}
                      </label>
                    </Row>
                    <Row>
                      <strong>
                        {selectedFemale?.lastCoverageDate
                          ? moment(selectedFemale?.lastCoverageDate).format(
                              "DD/MM/YYYY"
                            )
                          : ""}{" "}
                        {selectedFemale?.lastCoverageType ?? "-"}
                      </strong>
                    </Row>
                  </Col>
                  <Col span={6}>
                    <Row>
                      <label>
                        {
                          translation.animalPregnancyDiagnosis.form
                            .lastCoverageReproductionPeriodId
                        }
                      </label>
                    </Row>
                    <Row>
                      <strong>
                        {reproductionPeriods.find(
                          (reproductionPeriod) =>
                            reproductionPeriod.id ===
                            selectedFemale?.lastCoverageReproductionPeriodId
                        )?.description ?? "-"}
                      </strong>
                    </Row>
                  </Col>
                  <Col span={6}>
                    <Row>
                      <label>
                        {translation.animalPregnancyDiagnosis.form.lotName}
                      </label>
                    </Row>
                    <Row>
                      <strong>{selectedFemale?.lotName ?? "-"}</strong>
                    </Row>
                  </Col>
                  <Col span={6}>
                    <Row>
                      <label>
                        {
                          translation.animalPregnancyDiagnosis.form
                            .lotDestination
                        }
                      </label>
                    </Row>
                    <Row>
                      <SelectLotProduction
                        id="lotDestinationId"
                        name="lotDestinationId"
                        value={
                          values?.lotDestinationId != null
                            ? values?.lotDestinationId
                            : undefined
                        }
                        onlyActiveOrPending
                        onDeselect={() => {
                          setFieldValue("lotDestinationId", null);
                        }}
                        onChange={(value) =>
                          setFieldValue("lotDestinationId", value)
                        }
                      />
                    </Row>
                  </Col>
                </Row>
                {/* Row to select result, ovarian result and select coverage */}
                <Row type="flex" justify="space-around" gutter={8}>
                  <Col span={24}>
                    <SelectOvarianResult
                      result={values.result}
                      ovarianEvaluationResult={values.ovarianEvaluationResult}
                      onChangeOvarianResultCallback={(data) =>
                        setFieldValue("ovarianEvaluationResult", data)
                      }
                      onChangeResultCallback={(data) =>
                        handleChangeResult(data, setFieldValue)
                      }
                    />
                  </Col>
                  {selectedFemale &&
                    selectedFemale?.lastCoverages?.length > 1 &&
                    selectedFemale.femaleSituation === "servida" &&
                    values.result === 1 && (
                      <Col>
                        <ButtonSelectCoverage
                          type="button"
                          onClick={() => setIsModalDefineCoverageOpen(true)}
                        >
                          <LogoBullGreen />
                          Definir Cobertura
                        </ButtonSelectCoverage>
                      </Col>
                    )}
                </Row>
                {/* Row with fields: set gestationDays for MC/REPASSE, Discard Female field and Discard Motive */}
                <Row type="flex" gutter={8}>
                  {values.result === 1 &&
                    selectedFemale &&
                    (selectedFemale.femaleSituation === "vazia" ||
                      selectedFemale.femaleSituation === "servida") && (
                      <>
                        {((selectedFemale?.lastCoverageType === "MC/REPASSE" &&
                          values.result === 1 &&
                          selectedFemale?.lastCoverages.length === 1) ||
                          selectedFemale?.femaleSituation === "vazia") && (
                          <Col span={4}>
                            <Row>
                              <label
                                className={
                                  errors.gestationDays && submitCount > 0
                                    ? "error"
                                    : ""
                                }
                              >
                                {
                                  translation.animalPregnancyDiagnosis.form
                                    .timeGestationMC
                                }
                                *
                              </label>
                            </Row>
                            <Row
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <InputNumber
                                min={1}
                                value={values.gestationDays || undefined}
                                placeholder={translation.defaultPlaceholder}
                                max={
                                  form.femaleSituation === "servida"
                                    ? maxGestationDays
                                    : maxGestationDaysBreed
                                }
                                step={1}
                                onChange={(value) =>
                                  handleChangeMCGestationDays(
                                    value,
                                    setFieldValue
                                  )
                                }
                              />
                            </Row>
                          </Col>
                        )}
                        <Col span={4}>
                          <Row>
                            <label
                              className={
                                errors.gestationDays && submitCount > 0
                                  ? "error"
                                  : ""
                              }
                            >
                              {
                                translation.animalPregnancyDiagnosis.form
                                  .gestationDays
                              }
                            </label>
                          </Row>
                          <Row>
                            <strong>{values.gestationDays}</strong>
                          </Row>
                        </Col>
                        <Col span={4}>
                          <Row>
                            <label>
                              {
                                translation.animalPregnancyDiagnosis.form
                                  .birthForecast
                              }
                            </label>
                          </Row>
                          <Row>
                            <strong>
                              {values.birthForecast
                                ? moment(values.birthForecast).format(
                                    "DD/MM/YYYY"
                                  )
                                : "-"}
                            </strong>
                          </Row>
                        </Col>
                      </>
                    )}
                  {values.result === 2 &&
                    selectedFemale &&
                    selectedFemale?.animalFarmFunction === "reproduction" && (
                      <>
                        <Col span={4}>
                          <Row>
                            <label>
                              {
                                translation.animalPregnancyDiagnosis.form
                                  .shouldDiscardFemale
                              }
                            </label>
                          </Row>
                          <Row>
                            <Radio.Group
                              value={values.shouldDiscardFemale}
                              onChange={(e) =>
                                setFieldValue(
                                  "shouldDiscardFemale",
                                  e.target.value
                                )
                              }
                            >
                              <Row type="flex" gutter={8}>
                                <Col>
                                  <Radio.Button value={true}>Sim</Radio.Button>
                                </Col>
                                <Col>
                                  <Radio.Button value={false}>Não</Radio.Button>
                                </Col>
                              </Row>
                            </Radio.Group>
                          </Row>
                        </Col>
                        {values.shouldDiscardFemale === true && (
                          <Col span={6}>
                            <Row>
                              <label
                                className={
                                  errors.discardMotive && submitCount > 0
                                    ? "error"
                                    : ""
                                }
                              >
                                {
                                  translation.animalPregnancyDiagnosis.form
                                    .discardMotive
                                }
                                *
                              </label>
                            </Row>
                            <Row>
                              <Select
                                value={values.discardMotive || undefined}
                                placeholder={
                                  translation.defaultSelectPlaceholder
                                }
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={(value) =>
                                  setFieldValue("discardMotive", value)
                                }
                              >
                                {listDiscardMotives.map((discardMotive) => (
                                  <Select.Option
                                    key={discardMotive.id}
                                    value={discardMotive.value}
                                  >
                                    {discardMotive.label}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Row>
                          </Col>
                        )}
                      </>
                    )}
                </Row>
                <Modal
                  centered
                  width={700}
                  visible={isModalDefineCoverageOpen}
                  onCancel={handleModalCancel}
                  onOk={handleModalConfirm}
                >
                  <Row justify="center" align="middle" gutter={8}>
                    <Col span={1}>
                      <Icon
                        type="exclamation-circle"
                        style={{
                          color: "#faad14",
                        }}
                      />
                    </Col>
                    <Col span={23}>
                      <strong style={{ fontSize: 18 }}>
                        Selecione a cobertura responsável pela prenhes:
                      </strong>
                    </Col>
                  </Row>
                  <TableSelectCoverage>
                    <div className="header">MC/Repasse</div>
                    <div className="col title">
                      <label>Data</label>
                    </div>
                    <div className="col title">
                      <label>Reprodutor</label>
                    </div>
                    <div className="col title center">
                      <label>Gestação(dias)</label>
                    </div>
                    <div className="col value check">
                      <CustomCheckBox
                        checked={infoRepasseCoverage?.id === coverageSelectedId}
                        onChange={() => {
                          setCoverageSelectedId(infoRepasseCoverage?.id);
                        }}
                      />
                    </div>
                    <div className="col value">
                      <strong>
                        {moment(infoRepasseCoverage?.coverageDate).format(
                          "DD/MM/YYYY"
                        )}
                      </strong>
                    </div>
                    <div className="col value">
                      <strong>
                        {infoRepasseCoverage?.semenOrMaleIdentification}
                      </strong>
                    </div>
                    <div className="col value center">
                      <InputNumber
                        onKeyDown={handleKeyDownInputNumber}
                        className="inputPregnancy"
                        disabled={
                          infoRepasseCoverage?.id !== coverageSelectedId
                        }
                        value={coverageSelectedGestationDays || undefined}
                        placeholder="Digite"
                        min={1}
                        max={maxGestationDays}
                        step={1}
                        onChange={(value) =>
                          setCoverageSelectedGestationDays(value)
                        }
                      />
                    </div>
                    <div className="header">IA/IATF</div>
                    <div className="col title">
                      <label>Data</label>
                    </div>
                    <div className="col title">
                      <label>Reprodutor</label>
                    </div>
                    <div className="col title center">
                      <label>Gestação(dias)</label>
                    </div>
                    <div className="col value check">
                      <CustomCheckBox
                        checked={infoIaCoverage?.id === coverageSelectedId}
                        onChange={() => {
                          setCoverageSelectedId(infoIaCoverage?.id);
                        }}
                      />
                    </div>
                    <div className="col value">
                      <strong>
                        {moment(infoIaCoverage?.coverageDate).format(
                          "DD/MM/YYYY"
                        )}
                      </strong>
                    </div>
                    <div className="col value">
                      <strong>
                        {infoIaCoverage?.semenOrMaleIdentification}
                      </strong>
                    </div>
                    <div className="col value center">
                      <strong>
                        {moment(values.diagnosisDate).diff(
                          moment(infoIaCoverage?.coverageDate),
                          "days"
                        ) + 1}
                      </strong>
                    </div>
                  </TableSelectCoverage>
                </Modal>
                {/* Footer */}
                <Row
                  className="rowFooter"
                  type="flex"
                  justify="center"
                  align="middle"
                  gutter={8}
                >
                  <Col>
                    <ButtonStandard
                      type="submit"
                      name="buttonSaveFinancialTransactionsForm"
                      buttonType="principal"
                    >
                      {translation.buttons.save}
                    </ButtonStandard>
                  </Col>
                  <Col>
                    <ButtonStandard
                      tabIndex="-1"
                      name="buttonSaveFinancialTransactionsForm"
                      buttonType="secondary"
                      type="button"
                      onClick={() => handleCancel()}
                    >
                      {translation.buttons.cancel}
                    </ButtonStandard>
                  </Col>
                </Row>
              </form>
            )}
            <Row type="flex" justify="center" align="middle">
              {shouldCollapse ? (
                <button
                  type="button"
                  tabIndex="-1"
                  className="buttonArrowCollapse"
                  onClick={() => setShouldCollapse(false)}
                >
                  <ArrowUpIcon />
                </button>
              ) : (
                <button
                  type="button"
                  tabIndex="-1"
                  className="buttonArrowCollapse withMarginBottom"
                  onClick={() => setShouldCollapse(true)}
                >
                  <ArrowDownIcon />
                </button>
              )}
            </Row>
          </Spin>
        )}
      />
      <SelectAnimalsModal
        data={animals}
        visible={isVisibleModalSelectAnimal}
        selectedAnimalsKeys={selectedFemale ? [selectedFemale] : []}
        onConfirmSelection={handleConfirmSelectionAnimal}
        onCancel={handleCancelSelectionAnimal}
        isMultiple={false}
        forReproductionPurpose={true}
      />
    </Container>
  );
};

export default AnimalPregnancyDiagnosisForm;
